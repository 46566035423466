import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

@Component({
  selector: 'app-list-renderer-widget-currency',
  templateUrl: './list-renderer-widget-currency.component.html',
  styleUrls: ['./list-renderer-widget-currency.component.scss'],
})
export class ListRendererWidgetCurrencyComponent extends ListRendererWidgetBase {
  public code = 'EUR';
  public locale = 'nl';
  public display: 'symbol' | 'code' = 'symbol';
  public digitsInfo: string;

  constructor(public translateService: TranslateService) {
    super();
  }
}

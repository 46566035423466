export enum BuilderActionType {
  backendConfirmation = 'backend_confirmation',
  itemDownload = 'download',
  closePopup = 'close_popup',
  openPopup = 'open_popup',
  openSidebar = 'open_sidebar',
  backend = 'backend',
  customNavigation = 'custom-navigation',
  selectRow = 'select_row',
}

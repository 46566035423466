import { Component, EventEmitter, Output } from '@angular/core';
import { HeaderActionsService } from '@core/services/header-actions.service';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
})
export class HeaderActionsComponent {
  public headerActions$ = this.headerActionsService.headerActions$;

  @Output()
  public actionClick = new EventEmitter<boolean>();

  constructor(private headerActionsService: HeaderActionsService) {}

  public onActionCLick(callback: () => void): void {
    callback();
    this.actionClick.emit(true);
  }
}

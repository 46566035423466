import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { CapturumBuilderDateInputComponent } from '@capturum/builders/form-renderer';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent extends CapturumBuilderDateInputComponent implements OnDestroy, AfterViewInit {
  // @TODO: Ask BE to change the loading and unloading date keys, to be consistent in the entire project
  private loadingDateKeys = ['loading_date', 'departure_date'];

  private destroySubject$: Subject<boolean> = new Subject();

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next(true);
    this.destroySubject$.complete();
  }

  public ngAfterViewInit(): void {
    const minDateField = this.to.configurationOptions.minDateKey;
    const maxDateField = this.to.configurationOptions.maxDateKey;

    if (minDateField && maxDateField) {
      const listenedDateKey = `${this.field.key}`;
      const toChangeDateKey = this.field.key === minDateField ? maxDateField : minDateField;

      this.listenToDateChanges(listenedDateKey, toChangeDateKey);
    }
  }

  public listenToDateChanges(listenedDateKey: string, toChangeDateKey: string): void {
    this.formControl?.valueChanges
      ?.pipe(
        filter((value: string) => {
          return !this.to?.readonly && value !== null;
        }),
        takeUntil(this.destroySubject$),
      )
      .subscribe((value: string) => {
        const toChangeDateFormControl = this.formControl?.parent?.get(toChangeDateKey);
        const listenedDateValue = new Date(value);
        const toChangeDateValue = new Date(toChangeDateFormControl.value);
        const isDateInvalid = this.loadingDateKeys.includes(listenedDateKey)
          ? listenedDateValue > toChangeDateValue
          : listenedDateValue < toChangeDateValue;

        if (isDateInvalid) {
          toChangeDateFormControl.setValue(new Date(value), {
            emitEvent: false,
          });
          this.cdr.detectChanges();
        }
      });
  }
}

<div class="tab__header row no-gutters">
  <div [classList]="headingClass">
    <div class="page__heading">
      <ng-content select="[heading]"></ng-content>
    </div>
  </div>

  <div [classList]="actionClass">
    <div class="tab__actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-header-info',
  templateUrl: './table-header-info.component.html',
  styleUrls: ['./table-header-info.component.scss'],
})
export class TableHeaderInfoComponent {
  @Input()
  public totalItemsNumber: number;

  @Input()
  public additionalInfo: number;

  @Input()
  public title: string;
}

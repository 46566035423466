<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.list.action.allocate-product-detail' | translate }}
  </div>

  <div class="list-wrapper" *ngFor="let listConfig of listConfigs">
    <cpb-list-renderer
      *ngIf="item?.id && listConfig; else loadingSkeleton"
      [key]="listConfig.key"
      [context]="listConfig.context"
      (listRendererDataChange)="onListRendererDataChange(listConfig)">
    </cpb-list-renderer>

    <ng-container *ngIf="listConfig?.averagePrice !== null && listConfig?.averagePrice !== undefined">
      <div class="separator w-100"></div>

      <div class="average-sales-price p-2 mt-3 d-flex justify-content-end">
        <b class="mr-2">{{ 'demooij.allocation-details.average-price' | translate }}:</b>
        {{ listConfig?.averagePrice | currency : 'EUR' : 'symbol' }}
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loadingSkeleton>
  <cap-info-table-skeleton></cap-info-table-skeleton>
</ng-template>

import { Component, Input } from '@angular/core';
import { ActionMenu } from '@core/models/action-menu.model';

@Component({
  selector: 'app-panel-content-wrapper',
  templateUrl: './panel-content-wrapper.component.html',
  styleUrls: ['./panel-content-wrapper.component.scss'],
})
export class PanelContentWrapperComponent {
  @Input() public actions: ActionMenu[];
  @Input() public title: string;

  public executeAction(action: ActionMenu): void {
    if (!action.callback) {
      return;
    }

    action.callback();
  }
}

import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicFilterComponent, FilterConfigItem } from '@capturum/ui/dynamic-filters';
import { distinctUntilChanged, filter, startWith, Subject, takeUntil, tap, debounceTime, switchMap } from 'rxjs';
import {
  CapturumListRendererComponent,
  ListRendererConfig,
  ListRendererService,
} from '@capturum/builders/list-renderer';
import { map } from 'rxjs/operators';

export const STANDART_DEBOUNCE_TIME = 700;
export const DELAYED_DEBOUNCE_TIME = 5000;

@Component({
  selector: 'app-search-input-filter',
  templateUrl: './search-input-filter.component.html',
  styleUrls: ['./search-input-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchInputFilterComponent implements DynamicFilterComponent, OnInit, OnDestroy {
  public filterConfig: FilterConfigItem;
  public updateValue: EventEmitter<string> = new EventEmitter<string>();
  public control = new FormControl();
  public value: string;
  public debounceTime = STANDART_DEBOUNCE_TIME;

  private lastUpdatedValue: string;
  private destroy$ = new Subject<boolean>();

  constructor(
    public listRenderer: CapturumListRendererComponent,
    private listRendererService: ListRendererService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        switchMap((value) => {
          return this.listRendererService.getListConfigByKey(this.listRenderer.key).pipe(
            map((config: ListRendererConfig) => {
              return {
                value,
                config,
              };
            }),
          );
        }),
        tap((value) => {
          // When the user starts typing, revert to standard debounce time
          if (this.filterConfig?.debounceTime && value.value && this.debounceTime === DELAYED_DEBOUNCE_TIME) {
            this.debounceTime = STANDART_DEBOUNCE_TIME;
          }
        }),
        debounceTime(this.debounceTime),
        filter(({ value, config }) => {
          if (config?.options?.doNotLimit) {
            return value.length >= 0;
          } else {
            return value.length > 2 || value.length === 0;
          }
        }),
        startWith(this.control.value),
        distinctUntilChanged((prev, next) => {
          return (!prev && !next) || this.lastUpdatedValue === next.value;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        const valueToUpdate = typeof value === 'string' ? value : value?.value;

        this.updateFilterValue(valueToUpdate);
        this.lastUpdatedValue = valueToUpdate;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public updateFilterValue(value: string): void {
    this.updateValue.emit(value);
  }

  public setValue(value: string): void {
    this.control.setValue(value, { emitEvent: false });
    this.lastUpdatedValue = value;
    this.cdr.detectChanges();
  }

  public reset(): void {
    if (this.filterConfig?.debounceTime) {
      this.debounceTime = DELAYED_DEBOUNCE_TIME;
    }

    this.updateFilterValue(null);
    this.lastUpdatedValue = null;
    this.control.setValue('');
    this.cdr.detectChanges();
  }
}

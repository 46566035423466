import { Component, Input } from '@angular/core';
import { MapItem } from '@capturum/auth';

@Component({
  selector: 'app-details-values',
  templateUrl: './details-values.component.html',
  styleUrls: ['./details-values.component.scss'],
})
export class DetailsValuesComponent {
  @Input() public detail: MapItem;
}

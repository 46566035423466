import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { first, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TimeInputComponent extends FieldType implements OnInit, OnDestroy {
  public timeFormControl: UntypedFormControl = new UntypedFormControl();
  public inputType = 'string';

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly translateService: TranslateService,
    private readonly toastService: ToastNotificationService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.formControl?.valueChanges?.pipe(first(), takeUntil(this.destroy$)).subscribe((value) => {
      const formattedTime = value?.includes('.') ? value?.replace('.', ':') : value;

      this.timeFormControl.setValue(formattedTime, { emitEvent: false });
    });

    this.timeFormControl?.valueChanges?.pipe(takeUntil(this.destroy$)).subscribe((time) => {
      let value = time;

      if (value > 9999) {
        value = `${value}`.slice(0, 4);

        setTimeout(() => {
          this.timeFormControl.setValue(Number(value), { emitEvent: false });
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public setFocus(focused: boolean): void {
    this.inputType = focused ? 'number' : 'string';

    if (this.timeFormControl.value === '') {
      this.formControl.setValue(null);

      return;
    }

    if (this.timeFormControl.value < 0 || (!focused && `${this.timeFormControl.value}`.length > 4)) {
      this.validateTime();
      this.formControl.setValue(this.timeFormControl.value);
    } else if (this.timeFormControl.value || this.timeFormControl.value === 0) {
      const timeValue = `${focused ? this.timeFormControl.value.replace(':', '') : this.timeFormControl.value}`;
      let timeArray = timeValue?.split('');

      const digitsLength = timeArray.length;
      const missingDigits = new Array(4 - digitsLength).fill(0);

      timeArray = timeArray.concat(missingDigits);
      timeArray.splice(2, 0, ':');

      const timeWithColon = timeArray.join('');
      const timeWithoutColon = timeValue;

      this.timeFormControl.setValue(focused ? timeWithoutColon : timeWithColon);

      if (!focused) {
        this.validateTime();
        this.formControl.setValue(this.timeFormControl.value);
      }
    }
  }

  public validateTime(): void {
    const timeValue = this.timeFormControl.value.split('');

    if (timeValue.length > 4 && !timeValue.includes(':')) {
      this.toastService.error(this.translateService.instant('demooij.validations.invalid-time-format'));
      this.timeFormControl.setValue('00:00');
    }

    const hours = timeValue.slice(0, 2).join('');
    const minutes = timeValue.slice(3, 5).join('');

    if (hours >= 24 || minutes >= 60 || hours < 0) {
      this.toastService.error(this.translateService.instant('demooij.validations.invalid-time-format'));
      this.timeFormControl.setValue('00:00');
    }
  }
}

<div class="autocomplete-input">
  <cap-autocomplete
    *ngIf="!to?.readonly; else readonlyContainer"
    field="label"
    [(ngModel)]="autocompleteInputValue"
    [placeholder]="to?.readonly ? '' : ('placeholder.select' | translate)"
    [suggestionsFilter]="results$ | async"
    [delay]="400"
    (completeMethod)="search($event)"
    (onSelect)="customerSelected($event)"
    (blur)="removeCustomer()">
  </cap-autocomplete>

  <i
    *ngIf="!to?.readonly && showClearButton && autocompleteInputValue"
    class="autocomplete-clear-icon pi pi-times"
    (click)="clearAll()">
  </i>
</div>

<ng-template #readonlyContainer>
  <cpb-readonly-container>
    {{ autocompleteInputValue.label }}
  </cpb-readonly-container>
</ng-template>

<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.list.action.sales-details' | translate }}
  </div>

  <div class="list-wrapper">
    <cpb-list-renderer
      *ngIf="item?.id && purchaseOrderId; else loadingSkeleton"
      key="list_negative-stock-sales-details"
      [context]="{ product_id: item.id, purchase_order_id: purchaseOrderId }">
    </cpb-list-renderer>
  </div>

  <div class="title">
    {{ 'demooij.negative-stock.information' | translate }}
  </div>

  <div class="list-wrapper">
    <cpb-list-renderer
      *ngIf="item?.id; else loadingSkeleton"
      [key]="'list' | getKey : Entity.product : Entity.stock"
      [context]="{ product_id: item?.id }">
    </cpb-list-renderer>
  </div>

  <div class="title">
    {{ 'demooij.entity-name.' + Entity.supplierPrice + '.plural' | translate }}
  </div>

  <div class="list-wrapper">
    <cpb-list-renderer
      *ngIf="item?.id; else loadingSkeleton"
      key="list_product-negative-stock-supplier-price"
      [context]="{ product_id: item?.id }">
    </cpb-list-renderer>
  </div>
</div>

<ng-template #loadingSkeleton>
  <cap-info-table-skeleton></cap-info-table-skeleton>
</ng-template>

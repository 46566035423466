import { GenericPanelContentComponent } from './../generic-panel-content/generic-panel-content.component';
import { Component, ComponentFactoryResolver, ComponentRef, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentHostDirective } from '@shared/modules/layout/directives/component-host.directive';
import { ComponentType } from '@angular/cdk/overlay';
import { PanelComponentPrototype } from '@core/interfaces/panel-widget-prototype';

@Component({
  selector: 'app-dynamic-component',
  template: `<ng-template appComponentHost></ng-template>`,
})
export class DynamicComponentComponent {
  @ViewChild(ComponentHostDirective, { static: true })
  public componentHost: ComponentHostDirective;

  public componentRef: ComponentRef<PanelComponentPrototype>;
  private component: ComponentType<PanelComponentPrototype>;
  private options: Record<string, any>;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) {}

  // eslint-disable-next-line
  @Input('options') set setOptions(value: Record<string, any>) {
    this.options = value;

    if (value) {
      this.loadWidgetComponent();
    }
  }

  // eslint-disable-next-line
  @Input('component') set setComponent(value: ComponentType<PanelComponentPrototype>) {
    this.component = value ?? GenericPanelContentComponent;

    if (value) {
      this.loadWidgetComponent();
    }
  }

  private loadWidgetComponent(): void {
    this.componentRef = this.loadWidget(this.componentHost.viewContainerRef, this.component, this.options);
  }

  private loadWidget(
    viewContainerRef: ViewContainerRef,
    component: ComponentType<PanelComponentPrototype>,
    data?: Record<string, any>,
  ): ComponentRef<PanelComponentPrototype> {
    viewContainerRef.clear();

    const widgetComponent = viewContainerRef.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(component),
    );

    if (data) {
      Object.keys(data).forEach((key) => {
        widgetComponent.instance[key] = data[key];
      });
    }

    return widgetComponent;
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { hmrBootstrap } from './hmr';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryUrl) {
  Sentry.init({
    dsn: environment.sentryUrl,
    normalizeDepth: 0,
    environment: environment.name,
    ignoreErrors: [
      'Non-Error exception captured',
      'NG0100: ExpressionChangedAfterItHasBeenCheckedError',
    ],
  });
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}

<div class="header-actions">
  <ng-container *ngIf="headerActions$ | async as headerActions">
    <ng-container *ngIf="headerActions?.length">
      <ul class="actions">
        <li *ngFor="let action of headerActions" [ngClass]="action.styleClass" (click)="onActionCLick(action.callback)">
          <i [class]="action.icon"></i>
          {{ action?.title | translate }}
        </li>
      </ul>
    </ng-container>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InputWidgetService extends ApiService<any> {
  public changesInTheRow: Subject<{
    rowIndex?: number;
    changedFields: { [key: string]: any };
    savePayload?: boolean;
    id?: string;
    unique_id?: string;
  }> = new Subject();

  public submittingInputValueInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public submittingRequestsQueue: string[] = [];

  public saveInputValue(url: string, body: { [key: string]: any }): Observable<any> {
    return this.apiHttp.post(url, body);
  }

  public resetRequestQueue(): void {
    this.submittingInputValueInProgress$.next(false);
    this.submittingRequestsQueue = [];
  }

  public changeStatus(
    salesOrderId: string,
    inProgressStatusId: { status_base_data_value_id: string },
  ): Observable<void> {
    return this.apiHttp.put(`/sales-order/${salesOrderId}`, inProgressStatusId);
  }
}

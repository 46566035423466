<div *ngIf="!isMobile; else mobileHeader" class="header {{ logoUrl ? 'logo' : '' }}">
  <div class="row no-gutters">
    <ng-container *ngIf="logoUrl">
      <img
        [class.invisible]="logoCenter"
        [ngClass]="{ 'gemotra-logo': isPublicPage && isGemotraCompany }"
        alt="logo"
        [src]="logoUrl" />
    </ng-container>

    <ng-container *ngIf="pageConfig$ | async as config; else rawTemplate">
      <div *ngIf="!config.showBreadcrumbs; else breadcrumbsRef" class="col-4 header__back d-flex justify-content-start">
        <ng-container *ngTemplateOutlet="backUrlContainer; context: { config }"></ng-container>
      </div>

      <div [ngClass]="isMobile && isSalesOrders ? 'col-6' : 'col-4'" class="header__location">
        <div class="header__title">
          {{ config.title | observablePipe | async }}
        </div>

        <ng-container *ngIf="config?.subtitle as subtitle">
          <div class="header__connector">-</div>
          <div class="header__subtitle">
            {{ subtitle | observablePipe | async }}
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div *ngIf="showUserMenu" class="d-flex justify-content-end header__menu col-4">
      <ng-container *ngTemplateOutlet="userActions"></ng-container>
    </div>
  </div>
</div>

<ng-template #mobileHeader>
  <div class="mobile d-flex header w-100 align-items-center {{ logoUrl ? 'logo' : '' }}">
    <ng-container *ngIf="logoUrl && !logoCenter">
      <img
        [class.invisible]="logoCenter"
        [ngClass]="{ 'gemotra-logo': isPublicPage && isGemotraCompany }"
        alt="logo"
        [src]="logoUrl" />
    </ng-container>

    <div class="d-flex justify-content-between align-items-center w-100 h-100">
      <ng-container *ngIf="pageConfig$ | async as config; else rawTemplate">
        <div
          *ngIf="!config.showBreadcrumbs || (toShowBreadcrumb$ | async) === false; else breadcrumbsRef"
          class="header__back">
          <ng-container *ngTemplateOutlet="backUrlContainer; context: { config }"></ng-container>
        </div>

        <div *ngIf="isPublicPage || (toShowMobileTitle$ | async)" class="header__location">
          <div class="header__title">
            {{ config?.title | observablePipe | async }}
          </div>

          <ng-container *ngIf="config?.subtitle as subtitle">
            <div class="header__connector">-</div>
            <div class="header__subtitle">
              {{ subtitle | observablePipe | async }}
            </div>
          </ng-container>
        </div>
      </ng-container>

      <div *ngIf="showUserMenu" class="header__menu pr-2">
        <ng-container *ngTemplateOutlet="userActions"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #breadcrumbsRef>
  <app-breadcrumb class="col-4 header__back d-flex justify-content-start"></app-breadcrumb>
</ng-template>

<ng-template #backUrlContainer let-config="config">
  <ng-container *ngIf="config?.backUrl as backUrl; else backUrlMissing">
    <a (click)="goBack(config)">
      <i class="fa fa-chevron-left"></i>
      <span class="ml-1 nav-btn-back">{{ 'button.back-to-overview' | translate }}</span>
    </a>
  </ng-container>

  <ng-template #backUrlMissing>
    <a *ngIf="!config?.hideBackUrlMobile" class="mobile-back-to-overview" routerLink="/menu">
      <i class="fa fa-arrow-left"></i>
    </a>
  </ng-template>
</ng-template>

<ng-template #userActions>
  <cap-user-menu [menus]="menus" [user]="user$ | async">
    <div subtitle>{{ (activeCompany$ | async)?.name }}</div>

    <div class="d-flex flex-column" content>
      <app-header-actions (actionClick)="onHeaderActionClick()"></app-header-actions>

      <app-switch-company-list></app-switch-company-list>
    </div>
  </cap-user-menu>
</ng-template>

<ng-template #rawTemplate>
  <div class="col-3 header__back d-flex justify-content-start"></div>

  <div class="col-6 header__location">
    <img *ngIf="logoCenter" class="logo" alt="logo" [src]="logoUrl" />
  </div>
</ng-template>

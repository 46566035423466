import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InputWidgetBaseComponent } from '@core/bases/input-widget-base/input-widget-base.component';

@Component({
  selector: 'app-list-renderer-widget-text-input',
  templateUrl: './list-renderer-widget-text-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListRendererWidgetTextInputComponent extends InputWidgetBaseComponent {
  public disabled = false;

  public setFocus(focused: boolean): void {
    super.setFocus(focused);

    if (!focused) {
      this.inputValueChange.next(this.control.value);
    }
  }

  public checkForValueChange(): boolean {
    return this.item[this.field] !== this.control.value;
  }
}

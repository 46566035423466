export const API_DATE_PROPERTIES = [
  'loading_date',
  'purchase_date',
  'unloading_date',
  'sale_date',
  'delivery_date',
  'departure_date',
];

export const API_DATE_BLACKLIST = [];

export enum DatesFormats {
  displayFormat = 'dd-MM-yyyy',
  listFiltersFormat = 'yyyy-MM-dd',
  sendFormat = 'yyyy-MM-dd HH:mm:ss',
  isoDateFormat = 'd-M-Y H:mm',
}

import { Injectable } from '@angular/core';
import { ApiService, ListOptions, ApiHttpService, ApiIndexResult } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { User } from '@capturum/complete';
import { Company } from '@core/models/company.model';
import { Store } from '@ngxs/store';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { SetActiveCompany } from '@store/general/general.actions';
import { interval, map, Observable, Subject, switchMap, takeUntil, filter } from 'rxjs';
import { COMPANY_CHANGE_FETCH_INTERVAL } from '@core/constants/company-change-fetch-interval.constant';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService extends ApiService<Company> {
  public companiesList: Company[] = [];

  protected endpoint = 'company';
  private stopPolling: Subject<boolean> = new Subject<boolean>();

  constructor(
    public apiHttp: ApiHttpService,
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly toastService: ToastNotificationService,
  ) {
    super(apiHttp);
  }

  public setActive(companyId: string, options?: ListOptions): Observable<void> {
    let url = `/${this.endpoint}/${companyId}/set-active`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.post(url, {});
  }

  public startCompanyPolling(): void {
    this.startPolling()
      .pipe(
        filter((currentCompanyId) => {
          return this.authService.getUser()['current_company_id'] !== currentCompanyId;
        }),
        takeUntil(this.stopPolling.asObservable()),
      )
      .subscribe((currentCompanyId) => {
        const activeCompany = this.companiesList.find((company) => {
          return company.id === currentCompanyId;
        });
        const prevUser = JSON.parse(localStorage.getItem('user')) as User;
        const patchedUser = {
          ...prevUser,
          current_company_id: currentCompanyId,
        };

        localStorage.setItem('user', JSON.stringify(patchedUser));
        this.toastService.warning('demooij.toast.exception.company-changed');

        // Wait before refreshing the page
        setTimeout(() => {
          this.store.dispatch(new SetActiveCompany(activeCompany));
        }, 1000);
      });
  }

  public startPolling(): Observable<string> {
    return interval(COMPANY_CHANGE_FETCH_INTERVAL).pipe(
      switchMap((_) => {
        if (this.authService.getUser()?.id) {
          this.finishPeriodicalPolling();
        }

        return this.apiHttp.get<ApiIndexResult<User>>(`/user/${this.authService.getUser().id}`).pipe(
          map((result) => {
            return result.data['current_company_id'] as string;
          }),
        );
      }),
    );
  }

  public finishPeriodicalPolling(): void {
    this.stopPolling.next(true);
  }
}

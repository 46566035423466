import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@core/enums/entity.enum';
import { EntityAction } from '@core/enums/entity-action.enum';
import { combineLatest, Observable, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getAction',
})
export class GetActionPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(action: EntityAction, entity?: Entity): Observable<string> {
    let actionKey: Observable<string>;

    switch (action) {
      case EntityAction.create:
        actionKey = this.translateService.stream('button.new');
        break;
      case EntityAction.add:
        actionKey = combineLatest([
          this.translateService.stream('button.add'),
          this.translateService.stream('button.new'),
        ]).pipe(
          map(([addLabel, newLabel]) => {
            return `${addLabel} ${newLabel.toLowerCase()}`;
          }),
        );
        break;
      case EntityAction.update:
        actionKey = this.translateService.stream('button.edit');
        break;
      case EntityAction.delete:
        actionKey = this.translateService.stream('button.delete');
        break;
      default:
        break;
    }

    return zip(actionKey, this.translateService.stream(`demooij.entity-name.${entity}.singular`)).pipe(
      map(([actionTranslation, entityTranslation]) => {
        return `${actionTranslation} ${entityTranslation.toLowerCase()}`;
      }),
    );
  }
}

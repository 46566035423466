import { ActionButton } from '@core/models/action-button.model';
import { GetActionPipe } from '@shared/pipes/get-action.pipe';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChangedItem } from '@store/general/general-state.model';
import { GeneralSelectors } from '@store/general/general.selectors';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { BuildersListKey, Entity } from '@core/enums/entity.enum';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { TranslateService } from '@ngx-translate/core';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { EntityAction } from '@core/enums/entity-action.enum';
import { TableUpdateType } from '@core/enums/table-type.enum';
import { AppRoutes } from '@core/enums/routes.enum';
import { DestroyBase } from '@capturum/shared';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import {
  AdditionalPayloadForWidget,
  FetchActionsExecution,
  InputFocussed,
  SaveListRenderData,
  SetActiveCompany,
  SetBackUrl,
  UpdateChangedItem,
} from '@store/general/general.actions';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiIndexResult } from '@capturum/api';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { SetPanelConfig, UpdateItemInPanel } from '@store/panel/panel.actions';
import { BreadcrumbService } from '@core/services/breadcrumb.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { InputWidgetService } from '@core/services/input-widget.service';
import { PanelSelectors } from '@store/panel/panel.selectors';
import { PanelConfig } from '@core/models/panel-config.model';
import { saveAs } from 'file-saver';
import { ActionOptions } from '@capturum/builders/core';
import { Company } from '@core/models/company.model';

@Component({
  selector: 'app-entity-list-base',
  template: '',
})
export class EntityListBaseComponent extends DestroyBase implements OnInit, AfterViewInit, OnDestroy {
  @Select(GeneralSelectors.changedItem)
  public changedItem$: Observable<ChangedItem>;

  @Select(PanelSelectors.getConfig)
  public panelConfig$: Observable<PanelConfig>;

  public entity: Entity | BuildersListKey;
  public EntityAction: typeof EntityAction = EntityAction;
  public AppRoutes: typeof AppRoutes = AppRoutes;
  public listRenderer: CapturumListRendererComponent;
  public activeIndex = 0;
  public actionMenu: ActionButton[] = [];
  public actionButtons: ActionButton[] = [];
  public submitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public items: any[] = [];

  public submitKeyEvents = ['Enter', 'NumpadEnter'];
  public uniqueProperty = 'id';

  // The keys of the item we sent to backend on bulk updates
  protected itemsKeys: string[] = [];
  protected tableUpdateType: TableUpdateType = TableUpdateType.instant;
  protected translateService: TranslateService;
  protected actions: Actions;
  protected store: Store;
  protected route: ActivatedRoute;
  protected router: Router;
  protected getActionPipe: GetActionPipe;
  protected apiHttp: ApiHttpService;
  protected toastService: ToastNotificationService;
  protected redirectOnCompanyChange = false;
  private readonly breadcrumbService: BreadcrumbService;
  protected inputWidgetService: InputWidgetService;

  constructor(protected readonly cdr: ChangeDetectorRef, protected readonly injector: Injector) {
    super();
    this.translateService = injector?.get(TranslateService);
    this.actions = injector?.get(Actions);
    this.store = injector?.get(Store);
    this.route = injector?.get(ActivatedRoute);
    this.router = injector?.get(Router);
    this.getActionPipe = injector?.get(GetActionPipe);
    this.apiHttp = this.injector?.get(ApiHttpService);
    this.toastService = this.injector?.get(ToastNotificationService);
    this.breadcrumbService = this.injector?.get(BreadcrumbService);
    this.inputWidgetService = this.injector?.get(InputWidgetService);

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const paths = this.route.snapshot.routeConfig?.path?.split('/');
        const breadcrumbInfo = this.route.snapshot.data?.['breadcrumb'];
        const routeParams = this.route.snapshot.params;

        this.breadcrumbService.configureBreadcrumbs({
          paths,
          breadcrumbInfo,
          routeParams,
        });
      });

    // On Reload Page delete everything from store too
    window.onbeforeunload = () => {
      return this.ngOnDestroy();
    };
  }

  protected get defaultAddAction(): ActionButton {
    return {
      label: this.getAction(),
      styleClass: 'success',
      icon: 'fas fa-plus',
      callback: () => {
        this.router.navigateByUrl(`${this.router.url}/${AppRoutes.add}`);
      },
      permissions: [`${this.entity}.manage.create`],
    };
  }

  public ngOnInit(): void {
    this.setActionButtons();

    this.activeIndex = +this.route?.snapshot?.queryParamMap?.get('segment');

    this.actions
      .pipe(
        ofActionSuccessful(SetActiveCompany),
        filter(() => {
          return Boolean(this.redirectOnCompanyChange);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((data: { company: Company }) => {
        this.navigateOnCompanyChange(data.company);
      });
  }

  @HostListener('window:keyup', ['$event'])
  public keyUpevent(event: KeyboardEvent): void {
    if (this.tableUpdateType === TableUpdateType.bulk) {
      event.preventDefault();
      event.stopPropagation();

      if (this.submitKeyEvents.includes(event.code)) {
        this.onKeyEnterClick();
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.listRenderer) {
      this.listRenderer.alwaysResetFilterOnHide = true;
    }

    this.store.dispatch(new AdditionalPayloadForWidget(null));

    this.fetchRowSelectAction();
    this.fetchBackendAction();
    this.inputChangeListener();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.breadcrumbService.unsubscribeFromBreadcrumbs();
    this.inputWidgetService.resetRequestQueue();

    this.store.dispatch([
      new SetPanelConfig(null),
      new UpdateItemInPanel(null, null),
      new SaveListRenderData(null),
      new InputFocussed(null, null),
      new UpdateChangedItem(null, null, null),
    ]);
  }

  public fetchRowSelectAction(): void {
    this.actions
      .pipe(
        ofActionSuccessful(FetchActionsExecution),
        filter((test) => {
          return test.key === BuilderActionType.selectRow;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ({ item }) => {
          if (!item?.item) {
            return;
          }

          const indexSelectedRow = this.listRenderer?.selectedRows?.findIndex((row) => {
            return row.id === item.item.id;
          });

          this.postSelectRowAction(indexSelectedRow, item.item);

          this.cdr.detectChanges();
        },
      });
  }

  public postSelectRowAction(indexSelectedRow: number, item: any): void {
    if (indexSelectedRow > -1) {
      this.listRenderer.selectedRows.splice(indexSelectedRow, 1);
      this.listRenderer.selectedRows = [...this.listRenderer.selectedRows];
    } else {
      this.listRenderer.selectedRows = [...(this.listRenderer.selectedRows || []), item];
    }
  }

  public onListRendererDataChange(): void {
    if (this.tableUpdateType === TableUpdateType.bulk) {
      this.items = this.listRenderer?.data;
      this.store.dispatch(new SaveListRenderData(this.items));
    }

    const tableElement = document.getElementsByTagName('p-table')[0];

    if (!this.listRenderer?.data?.length || this.listRenderer.data.length < 5) {
      tableElement.classList.add('no-sticky-header');
    } else {
      tableElement.classList.remove('no-sticky-header');
    }
  }

  public switchActiveButton(event: number): void {
    this.activeIndex = event;
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: { segment: this.activeIndex },
    });
  }

  // This method serves purely as a hook
  public onKeyEnterClick(): void {}

  protected fetchBackendAction(): void {
    this.actions
      .pipe(
        ofActionSuccessful(FetchActionsExecution),
        filter(({ key }) => {
          return key === BuilderActionType.backend;
        }),
        switchMap(({ item }) => {
          return this.postBackendAction(item);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (response: any) => {
          if (response?.file) {
            this.saveFile(response.file);
          }
          if (
            !response?.meta?.['messages']?.some((message) => {
              return message.type === 'warning';
            }) &&
            response?.options?.message
          ) {
            this.toastService.success(response?.options?.message);
          }

          if (this.listRenderer?.selectedRows?.length) {
            this.listRenderer.selectedRows = [];
          }

          this.updateListRender();
        },
        error: () => {
          this.errorFetchBackendAction();
        },
      });
  }

  // This method serves purely as a hook
  protected errorFetchBackendAction(): void {}

  protected postBackendAction(item: {
    item: { id: string; [key: string]: any };
    options: ActionOptions;
  }): Observable<unknown> {
    return this.apiHttp
      .post<ApiIndexResult<Record<string, any>>>(
        item?.options?.action?.options?.endpoint,
        Array.isArray(item?.item) ? item?.item : [item?.item],
      )
      .pipe(
        map((response) => {
          return {
            meta: response?.meta,
            options: item?.options,
          };
        }),
      );
  }

  protected saveFile(response: Blob): void {
    saveAs(response);
  }

  protected updateListRender(): void {
    this.listRenderer?.updateTableData();
  }

  // For bulk tables, there is no need to update the entire list renderer, we update just this.items which
  // will contain all the items user has changed
  protected updateRowDataLocally(newItem: any): void {
    if (this.listRenderer?.data?.length) {
      const itemInListRenderer = this.listRenderer?.data.find((listRendererItem) => {
        return listRendererItem?.[this.uniqueProperty] === newItem?.[this.uniqueProperty];
      });

      if (
        !this.items.find((item) => {
          return item?.[this.uniqueProperty] === itemInListRenderer?.[this.uniqueProperty];
        })
      ) {
        this.items.push(itemInListRenderer);
      }
    }

    if (newItem && this.items?.length) {
      this.items = this.items.map((item) => {
        return item?.[this.uniqueProperty] === newItem?.[this.uniqueProperty]
          ? {
              ...item,
              ...newItem,
            }
          : item;
      });
    }

    this.store.dispatch(new SaveListRenderData(this.items));
  }

  protected updateRowDataInListRenderer(newItem: any): void {
    if (this.listRenderer?.data && newItem) {
      const updatedList = this.listRenderer?.data?.map((item) => {
        if (item?.[this.uniqueProperty] === newItem?.[this.uniqueProperty]) {
          return {
            ...item,
            ...newItem,
          };
        }

        return item;
      });

      this.store.dispatch(new SaveListRenderData(this.rowsWithValue(updatedList)));

      if (this.listRenderer) {
        this.listRenderer.data = updatedList;
        this.listRenderer?.['cdr'].detectChanges();
      }
    }
  }

  protected inputChangeListener(): void {
    this.changedItem$
      .pipe(
        filter((item) => {
          return item && this.listenForInputChanges(item);
        }),
        filter((item) => {
          return (
            this.tableUpdateType === TableUpdateType.bulk ||
            item.index !== this.store.selectSnapshot(GeneralSelectors.focusedRowIndex)
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((item) => {
        switch (this.tableUpdateType) {
          case TableUpdateType.instant:
            this.updateRowDataInListRenderer(item);
            break;
          case TableUpdateType.bulk:
            this.updateRowDataLocally(item);
            break;
          default:
            break;
        }
      });
  }

  // This method serves purely as a hook
  protected listenForInputChanges(item: ChangedItem): boolean {
    return false;
  }

  // This method serves purely as a hook
  protected setActionButtons(): void {}

  protected getAction(
    action: EntityAction = EntityAction.create,
    entity: Entity = this.entity as Entity,
  ): Observable<string> {
    return this.getActionPipe.transform(action, entity);
  }

  // To add posibility if needed to send an array of minimumRequiredField
  protected rowsWithValue(
    data: any[],
    itemKeys?: string[],
    minimumRequiredFields?: string[],
    allowZero?: boolean,
  ): any[] {
    return (
      (data?.length &&
        data?.reduce((acc, item) => {
          if (
            !itemKeys ||
            itemKeys.every((property) => {
              return !!item[property];
            }) ||
            minimumRequiredFields?.some((field) => {
              return !!item[field] || (allowZero && item[field] === 0);
            })
          ) {
            const newItemObject = !itemKeys ? item : {};

            if (itemKeys?.length) {
              itemKeys.forEach((property) => {
                newItemObject[property] = item[property];
              });
            }

            return [
              ...acc,
              {
                ...newItemObject,
                [this.uniqueProperty]: item?.[this.uniqueProperty],
              },
            ];
          }

          return acc;
        }, [])) ||
      []
    );
  }

  protected successfullySubmitted(): void {
    this.items = [];
    this.store.dispatch(new SaveListRenderData(null));
    this.store.dispatch(new UpdateChangedItem(null, null, null));
    this.store.dispatch(new InputFocussed(null));
    this.submitting$.next(false);

    this.updateListRender();
    this.listRenderer?.['cdr'].detectChanges();
  }

  protected navigateOnCompanyChange(company: Company): void {
    const pageConfig = this.store.selectSnapshot(GeneralSelectors.getPageConfig);

    if (pageConfig?.backUrl) {
      this.router.navigateByUrl(`${pageConfig.backUrl}`);
    }
  }

  protected setBackUrl(): void {
    this.store.dispatch(new SetBackUrl(this.backUrl));
  }

  protected get backUrl(): string {
    return '';
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getTranslation',
})
export class GetTranslationPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(key: string): Observable<string> {
    return this.translateService.stream(`demooij.list.column.${key.replace(/_/g, '-')}`);
  }
}

<cap-multi-select
  [formControl]="formControl"
  [options]="results$ | async"
  [optionLabel]="to.label_key"
  [optionValue]="to.value_key"
  [resetFilterOnHide]="true"
  [virtualScroll]="true"
  [appendTo]="'body'"
  (filtered)="search($event)">
</cap-multi-select>

<div class="panel-content row-2">
  <div class="title">
    {{ 'demooij.entity-name.' + Entity.stock + '.plural' | translate }}
  </div>
  <div class="list-wrapper">
    <cpb-list-renderer
      *ngIf="item?.id; else loadingSkeleton"
      key="list_product-stock"
      [context]="{ product_id: item?.id }">
    </cpb-list-renderer>
  </div>

  <div class="title">
    {{ 'demooij.entity-name.' + Entity.supplierPrice + '.plural' | translate }}
  </div>
  <div class="list-wrapper">
    <cpb-list-renderer
      *ngIf="item?.id; else loadingSkeleton"
      key="list_product-supplier-price"
      [context]="{ product_id: item?.id }">
    </cpb-list-renderer>
  </div>
</div>

<ng-template #loadingSkeleton>
  <cap-info-table-skeleton></cap-info-table-skeleton>
</ng-template>

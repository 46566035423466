import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FieldType } from '@ngx-formly/core';
import { ProductApiService } from '@core/api/product-api.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { CapturumMultiSelectComponent } from '@capturum/ui/multi-select';
import { responseData } from '@capturum/builders/core';
import { AutocompleteItem } from '@core/interfaces/autocomplete-item.interface';

@Component({
  selector: 'app-product-multiselect-dropdown',
  templateUrl: './product-multiselect-dropdown.component.html',
  styleUrls: ['./product-multiselect-dropdown.component.scss'],
})
export class ProductMultiselectDropdownComponent extends FieldType implements OnInit, AfterViewInit {
  @ViewChild(CapturumMultiSelectComponent)
  public multiSelect: CapturumMultiSelectComponent;

  public results$: Observable<AutocompleteItem[]>;
  private filterEvent: Subject<string> = new Subject<string>();

  constructor(public productApiService: ProductApiService) {
    super();
  }

  public ngOnInit(): void {
    this.results$ = this.filterEvent.pipe(
      debounceTime(400),
      switchMap((search: string) => {
        return this.productApiService
          .list({
            search,
          })
          .pipe(responseData);
      }),
    );
  }

  public ngAfterViewInit(): void {
    /**
     * This rewrite of parent method is for prevent default filtering
     */
    this.multiSelect.pMultiSelect.activateFilter = () => {};
  }

  public search(event: { originalEvent: InputEvent; filter: string }): void {
    if (event?.filter) {
      this.filterEvent.next(event?.filter);
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { BreadcrumbItem } from '@core/interfaces/breadcrumb-item.interface';
import { BreadcrumbService } from '@core/services/breadcrumb.service';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeneralSelectors } from '@store/general/general.selectors';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { SaveListRenderData } from '@store/general/general.actions';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  public AppRoutes = AppRoutes;
  public breadcrumbs$: Observable<BreadcrumbItem[]> = this.breadcrumbService.breadcrumbs$.asObservable();

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  public onNavigateClick(breadcrumb: BreadcrumbItem): void {
    const warnBeforeRedirect = this.store.selectSnapshot(GeneralSelectors.getPageConfig).warnBeforeRedirect;
    const itemsSavedLocally = this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData);

    if (warnBeforeRedirect && itemsSavedLocally?.length) {
      this.confirmationService.confirm({
        message: this.translateService.instant('demooij.back-to-overview.data-lost'),
        header: this.translateService.instant('demooij.alert.confirmation'),
        accept: () => {
          this.redirectToPage(breadcrumb);
        },
      });

      return;
    }

    this.redirectToPage(breadcrumb);
  }

  public redirectToPage(breadcrumb: BreadcrumbItem): void {
    this.store.dispatch(new SaveListRenderData(null));

    this.router.navigate([`${AppRoutes.admin}/${breadcrumb.path}`], {
      queryParams: breadcrumb.queryParams,
    });
  }
}

export enum EntityAction {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  add = 'add',
  download = 'download',
  copy = 'copy',
  print = 'print',
}

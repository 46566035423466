import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { OrderProductsWarningComponent } from '../../components/order-products-warning/order-products-warning.component';
import { PublicSelectors } from '@store/public/public.selectors';
import { AppRoutes } from '@core/enums/routes.enum';
import { filter, first } from 'rxjs/operators';
import { CustomColumn } from '@public/pricey/interfaces/custom-pricey-column.enum';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';
import { Product } from '@core/models/entity/product.model';
import { EntityCustomListBaseComponent } from '@shared/modules/entity-base/components/entity-custom-list-base/entity-custom-list-base.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { PublicPriceyService } from '@public/pricey/services/public-pricey.service';
import { DialogService } from 'primeng/dynamicdialog';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { ListDownloadService } from '@core/actions/list-download.service';
import { ActionOptions } from '@capturum/builders/core';
import { DatePipe } from '@angular/common';
import { SetEditedProducts } from '@store/public/public.actions';
import { ToastService } from '@capturum/ui/api';
import { PollingHandlerService } from '../../services/polling-handler.service';

@Component({
  selector: 'app-public-order-product',
  templateUrl: './public-order-product.component.html',
  styleUrls: ['./public-order-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PublicOrderProductComponent extends EntityCustomListBaseComponent implements OnInit, OnDestroy {
  public productKey = 'product';
  public isCashCarryCompany: boolean;
  public isGemotraCompany: boolean;
  public editedItems: Product[] = [];

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly store: Store,
    protected readonly router: Router,
    private readonly datePipe: DatePipe,
    protected readonly publicPriceyService: PublicPriceyService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly breakPointService: BreakPointService,
    protected readonly toastService: ToastService,
    private listDownloadService: ListDownloadService,
    private readonly pollingHandlerService: PollingHandlerService,
  ) {
    super(translateService, store, publicPriceyService, dialogService, changeDetectorRef, breakPointService);
  }

  public ngOnInit(): void {
    const savedProductList = this.store.selectSnapshot(PublicSelectors.getEditedProductsList);

    this.editedItems = savedProductList?.length ? [...savedProductList] : [];

    this.isCashCarryCompany =
      this.store.selectSnapshot(PublicSelectors.getCustomer)?.companyCode === CompanyCodesEnum.cashcarry;
    this.isGemotraCompany =
      this.store.selectSnapshot(PublicSelectors.getCustomer)?.companyCode === CompanyCodesEnum.gemotra;

    this.configureActions();
    this.configureColumns();

    if (this.isCashCarryCompany) {
      this.addCashAndCarryInputs();
    }

    if (this.isGemotraCompany) {
      this.removeHiddenColumns();
    }

    this.isTableReady = true;
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.pollingHandlerService.finishPeriodicalPolling();
  }

  public onTableDataChange(editedProduct: Product): void {
    const oldProductId = this.editedItems.findIndex((product) => {
      return product.id === editedProduct.id;
    });

    if (oldProductId >= 0) {
      this.editedItems[oldProductId] = { ...editedProduct };
    } else {
      this.editedItems.push(editedProduct);
    }

    this.editedItems = this.editedItems.filter((item) => {
      return item?.quantity || item?.remarks || item?.unit;
    });

    this.store.dispatch(new SetEditedProducts(this.editedItems));
  }

  public openPlaceOrderWarning(): void {
    const validProducts = this.getValidEditedProducts();

    if (!validProducts.length) {
      const title = this.translateService.instant('demooij.toast.warning.title');
      const message = this.translateService.instant('demooij.toast.warning.no-items-in-cart');

      this.toastService.warning(title, message);

      return;
    }

    this.dialogService
      .open(OrderProductsWarningComponent, {
        header: this.translateService.instant('demooij.public.pricey.confirmation.title'),
      })
      ?.onClose.pipe(first(), filter(Boolean))
      .subscribe(() => {
        this.store.dispatch(new SetEditedProducts(validProducts));

        this.router.navigate(['/', AppRoutes.public, AppRoutes.pricey, AppRoutes.placeOrder]);
      });
  }

  protected configureActions(): void {
    this.actionButtons = [
      {
        label: this.translateService.stream('demooij.public.pricey.place-order'),
        styleClass: 'success',
        icon: 'fas fa-shopping-cart',
        hidden: this.isGemotraCompany,
        callback: () => {
          return this.openPlaceOrderWarning();
        },
      },
      {
        label: this.translateService.instant('demooij.public.btn.download-as-pdf'),
        styleClass: 'primary',
        icon: 'fas fa-download',
        callback: () => {
          return this.priceyDownload();
        },
      },
    ];
  }

  protected configureColumns(): void {
    this.columns = [
      {
        field: 'name',
        header: 'demooij.list.column.name',
        template: CustomColumn.productName,
      },
      { field: 'brand', header: 'demooij.list.column.brand', hidden: this.isGemotraCompany },
      { field: 'details', header: 'demooij.list.column.details' },
      { field: 'size', header: 'demooij.list.column.size', hidden: this.isGemotraCompany },
      { field: 'origin', header: 'demooij.list.column.origin', hidden: this.isGemotraCompany },
      { field: 'category', header: 'demooij.list.column.category', hidden: this.isGemotraCompany },
      {
        field: 'net_weight',
        header: 'demooij.list.column.net-weight',
        template: CustomColumn.netPrice,
        hidden: this.isGemotraCompany,
      },
      {
        field: 'packages_per_pallet',
        header: 'demooij.list.column.amount-per-pallet',
        hidden: this.isGemotraCompany,
      },
      { field: 'packaging', header: 'demooij.list.column.packaging', hidden: this.isGemotraCompany },
      {
        field: 'customer_price',
        header: 'demooij.list.column.price',
        template: CustomColumn.customerPrice,
      },
      {
        field: 'quantity',
        header: 'demooij.list.column.quantity',
        template: CustomColumn.quantity,
        hidden: this.isGemotraCompany,
        isInput: true,
      },
      {
        field: 'remarks',
        header: 'demooij.list.column.remarks',
        template: CustomColumn.remarks,
        hidden: this.isGemotraCompany,
        isInput: true,
      },
      {
        field: '',
        header: '',
        hidden: !this.isGemotraCompany && this.isMobile,
      },
    ];
  }

  private getValidEditedProducts(): Product[] {
    return this.editedItems.filter((product) => {
      if (this.isCashCarryCompany) {
        const inputsBothZero = product?.quantity === 0 && product?.unit === 0;
        const inputsIsEmpty = product?.quantity === undefined && product?.unit === undefined;
        const validUnit = product?.unit ? product?.unit < product.amount_per_package : !!product?.quantity;

        return !inputsBothZero && !inputsIsEmpty && validUnit;
      }

      return product?.quantity;
    });
  }

  private addCashAndCarryInputs(): void {
    this.columns.splice(12, 0, {
      field: 'unit',
      header: 'demooij.list.column.unit',
      template: CustomColumn.unit,
      isInput: true,
    });
    this.columns.splice(9, 0, {
      field: 'customer_unit_price',
      header: 'demooij.list.column.colli-price',
      template: CustomColumn.customerPrice,
    });
  }

  private removeHiddenColumns(): void {
    this.columns = this.columns.filter((column) => {
      return !column.hidden;
    });

    this.isBottomReached = true;
  }

  private priceyDownload(): void {
    const entityName = this.translateService.instant('demooij.base-data.price-lists');
    const currentDate = this.datePipe.transform(new Date(), 'dd/MM/YYYY_HH:mm');
    const fileName = `${entityName}_${currentDate}`;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const option: ActionOptions = {
      configurationKey: PublicOrderProductComponent.name,
      action: {
        key: 'download',
        type: 'backend',
        options: {
          endpoint: {
            url: '/pricey/download',
            params: {
              timezone,
            },
          },
          executePolling: true,
        },
      },
    };

    this.listDownloadService.execute(option, { fileName });
  }
}

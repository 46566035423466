import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResult, ApiService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends ApiService<any> {
  public endpoint = 'customer';

  public getProducts(mainGroupIds: string[]): Observable<ApiListResult> {
    let url = `/product/list`;

    const options = this.getOptionsQuery({
      filters: [
        {
          field: 'productGroup.mainGroup.id',
          operator: 'in',
          value: mainGroupIds,
        },
      ],
    });

    url += options;

    return this.apiHttp.get(url);
  }

  public saveForm(customerId: string, value: { product_ids: string[] }): Observable<any> {
    return this.apiHttp.post(`/customer/${customerId}/customer-product`, value);
  }

  public mainGroups(): Observable<ApiListResult> {
    return this.apiHttp.get('/main-group/list');
  }
}

import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomNavigationService implements ActionProvider {
  constructor(private router: Router) {}

  public execute(options: ActionOptions, item: any): void {
    const navigationRoute = options?.route?.url.replace(options?.route?.identifierKey, item?.id);

    this.router.navigateByUrl(this.router.url.concat(navigationRoute));
  }
}

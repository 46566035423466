import { PanelConfig } from '@core/models/panel-config.model';

export class SetPanelConfig {
  public static readonly type = '[PANEL] Set panel config';

  constructor(public config: PanelConfig) {}
}

export class UpdateItemInPanel {
  public static readonly type = '[PANEL] Update item in panel';

  constructor(public item: Record<string, any>, public panelTitle: string) {}
}

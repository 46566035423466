import { PanelBase } from '@core/classes/panel-base.class';
import { BuilderAction } from '@capturum/builders/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { get, cloneDeep } from 'lodash';

interface Content {
  title: string;
  key: string;
  context?: object;
}

@Component({
  selector: 'app-generic-panel-content',
  templateUrl: './generic-panel-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericPanelContentComponent extends PanelBase implements OnInit {
  public item: any;
  public action: BuilderAction & {
    title: string;
    options: { content: Content[] };
  };

  public contents: Content[] = [];

  public ngOnInit(): void {
    if (this.action?.options?.content) {
      const contents: Content[] = cloneDeep(this.action.options.content);

      this.contents = contents.map((content) => {
        if (content.context) {
          Object.keys(content.context).forEach((key) => {
            const element = content.context[key];

            content.context[key] = get(this.item, element) || element;
          });
        }

        return content;
      });
    }
  }
}

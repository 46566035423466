<div class="public-layout">
  <div class="public-layout__page">
    <div class="public-layout__header">
      <app-header *ngIf="customer$ | async as customer" [logoUrl]="customer.logo" [isPublicPage]="true"></app-header>
    </div>

    <div class="public-layout__content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

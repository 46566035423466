import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkNullUndefined',
})
export class CheckNullUndefinedPipe implements PipeTransform {
  public transform(value: number): boolean {
    return value !== null && value !== undefined;
  }
}

import { map, switchMap } from 'rxjs/operators';
import { SetCustomer } from '@store/public/public.actions';
import { DatePipe } from '@angular/common';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PriceyService } from '../api/pricey-api.service';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { CompanyColorsEnum } from '@core/enums/company-colors.enum';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';

@Injectable({
  providedIn: 'root',
})
export class PublicCustomerResolver implements Resolve<boolean> {
  constructor(
    private store: Store,
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private priceyService: PriceyService,
    private readonly themeService: ThemeService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.priceyService.showLoader.next(true);

    let token = route?.queryParams?.token;

    if (!token) {
      token = localStorage.getItem('public_token');
    }

    if (token) {
      localStorage.setItem('token', token);
      localStorage.setItem('public_token', token);
    }

    return combineLatest([
      this.priceyService.getCustomer({ include: ['locale', 'currency'] }).pipe(
        map((customer) => {
          if (customer?.locale && !['en', 'nl'].includes(customer.locale?.code)) {
            return {
              ...customer,
              locale: {
                ...customer.locale,
                code: 'en',
              },
            };
          }

          return customer;
        }),
      ),
      this.priceyService.getCompany(),
    ]).pipe(
      switchMap(([customer, company]) => {
        if (customer.locale?.code) {
          this.translateService.use(customer.locale.code);
        }

        this.themeService.setProps({
          [CSSVarNames.Primary]: CompanyColorsEnum[company?.code] || CompanyColorsEnum.gemotra,
        });

        const hours = new Date(customer?.expires_on).getHours();
        const minutes = new Date(customer?.expires_on).getMinutes();

        return this.store.dispatch(
          new SetCustomer({
            ...customer,
            id: token,
            currency: customer?.currency,
            companyCode: CompanyCodesEnum[company?.code],
            expires_on: this.datePipe.transform(
              new Date(customer?.expires_on).setHours(hours, minutes),
              'dd-MM-YYYY HH:mm',
            ),
            logo: `/assets/images/${company.logo || 'logo.png'}`,
          }),
        );
      }),
      map(Boolean),
    );
  }
}

import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

@Component({
  selector: 'app-list-renderer-widget-integer',
  template: `{{ item[field] | number : '1.2-2' : 'nl' }}`,
})
export class ListRendererWidgetIntegerComponent extends ListRendererWidgetBase {}

import { ChangedItem } from '@store/general/general-state.model';
import { Selector } from '@ngxs/store';
import { GeneralState } from './general.state';
import { GeneralStateModel } from './general-state.model';
import { Company } from '@core/models/company.model';
import { StatePageConfig } from '@core/models/page-config.model';

export class GeneralSelectors {
  @Selector([GeneralState])
  public static getSubmitting(state: GeneralStateModel): Record<string, boolean> {
    return state.submitting;
  }

  @Selector([GeneralState])
  public static getActiveCompany(state: GeneralStateModel): Company {
    return state.company;
  }

  @Selector([GeneralState])
  public static getPageConfig(state: GeneralStateModel): StatePageConfig {
    return state.pageConfig;
  }

  @Selector([GeneralState])
  public static changedItem(state: GeneralStateModel): ChangedItem {
    return state.item;
  }

  @Selector([GeneralState])
  public static focusedRowIndex(state: GeneralStateModel): number {
    return state.focusedRowIndex;
  }

  @Selector([GeneralState])
  public static focusedFieldName(state: GeneralStateModel): string {
    return state.focusedFieldName;
  }

  @Selector([GeneralState])
  public static unloadingLocation(state: GeneralStateModel): string {
    return state.unloadingLocation;
  }

  @Selector([GeneralState])
  public static additionalPayload(state: GeneralStateModel): object {
    return state.additionalPayload;
  }

  @Selector([GeneralState])
  public static getSavedListRenderData(state: GeneralStateModel): any[] {
    return state.listRenderData;
  }
}

<div class="custom-forms">
  <div class="custom-forms__wrapper pb-5">
    <span>{{ 'demooij.public.pricey.confirmation.text' | translate }}</span>
  </div>

  <div class="custom-forms__buttons">
    <cap-button
      styleClass="cap-button__cancel"
      [label]="'demooij.public.general.cancel' | translate"
      [attr.data-test]="'cancel-order-products-warning-btn'"
      (onClick)="ref.close()">
    </cap-button>

    <cap-button
      styleClass="cap-button__primary"
      [label]="'demooij.public.general.yes-sure' | translate"
      [attr.data-test]="'submit-order-products-warning-btn'"
      (onClick)="ref.close(true)">
    </cap-button>
  </div>
</div>

import { SetCustomer, SetEditedProducts, SetProducts } from './public.actions';
import { PublicStateModel } from './public-state.model';
import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

@State<PublicStateModel>({
  name: 'public',
})
@Injectable()
export class PublicState {
  @Action(SetProducts)
  public setPublicConfig(ctx: StateContext<PublicStateModel>, { products }: SetProducts): void {
    const state = ctx.getState();
    const stateValue = {
      ...state,
      products,
    };

    ctx.setState(stateValue);
  }

  @Action(SetCustomer)
  public setCustomer(ctx: StateContext<PublicStateModel>, { customer }: SetCustomer): void {
    const state = ctx.getState();
    const stateValue = {
      ...state,
      customer,
    };

    ctx.setState(stateValue);
  }

  @Action(SetEditedProducts)
  public setEditedProducts(ctx: StateContext<PublicStateModel>, { editedProducts }: SetEditedProducts): void {
    const state = ctx.getState();
    const stateValue = {
      ...state,
      editedProducts: [...editedProducts],
    };

    ctx.setState(stateValue);
  }
}

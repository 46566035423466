import { DeMooijLoginService } from './services/de-mooij-login.service';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AuthModule } from '@capturum/auth';
import { environment } from '@environments/environment';
import { ApiModule } from '@capturum/api';
import { LoginModule } from '@capturum/login';
import { CompleteModule } from '@capturum/complete';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListRendererWidgetCurrencyComponent } from './builders/list/list-renderer-widget-currency/list-renderer-widget-currency.component';
import { ListRendererWidgetCheckboxComponent } from './builders/list/list-renderer-widget-checkbox/list-renderer-widget-checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { ListRendererWidgetInputComponent } from './builders/list/list-renderer-widget-input/list-renderer-widget-input.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { HttpCodeInterceptor } from '@core/interceptors/http-code.interceptor';
import { ListRendererWidgetDropdownComponent } from '@core/builders/list/list-renderer-widget-dropdown/list-renderer-widget-dropdown.component';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { ListRendererWidgetMinusPrefixComponent } from './builders/list/list-renderer-widget-minus-prefix/list-renderer-widget-minus-prefix.component';
import { ListRendererWidgetTextStyleByConditionComponent } from './builders/list/list-renderer-widget-text-style-by-condition/list-renderer-widget-text-style-by-condition.component';
import { API_DATE_BLACKLIST, API_DATE_PROPERTIES, DatesFormats } from './configs/date-config';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { ListRendererWidgetTextInputComponent } from './builders/list/list-renderer-widget-text-input/list-renderer-widget-text-input.component';
import { InputWidgetBaseComponent } from './bases/input-widget-base/input-widget-base.component';
import * as Sentry from '@sentry/angular-ivy';
import { TimeInputComponent } from './builders/form/time-input/time-input.component';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CalendarModule } from 'primeng/calendar';
import { PriceInputComponent } from './builders/form/price-input/price-input.component';
import { ListRendererWidgetStatusIconComponent } from './builders/list/list-renderer-widget-status-icon/list-renderer-widget-status-icon.component';
import { ListRendererWidgetIntegerComponent } from '@core/builders/list/list-renderer-widget-integer/list-renderer-widget-integer.component';
import { AutocompleteCustomerComponent } from './builders/form/autocomplete-customer/autocomplete-customer.component';
import { CapturumAutocompleteModule } from '@capturum/ui/autocomplete';
import { SharedModule } from '@shared/shared.module';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { ProductMultiselectDropdownComponent } from './builders/form/product-multiselect-dropdown/product-multiselect-dropdown.component';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { ListRendererWidgetDateComponent } from '@core/builders/list/list-renderer-widget-date/list-renderer-widget-date.component';
import { DateInputComponent } from './builders/form/date-input/date-input.component';
import { Router } from '@angular/router';
import { SentryErrorHandler } from './sentry/error-handler';
import { ListRendererWidgetBooleanComponent } from './builders/list/list-renderer-widget-boolean/list-renderer-widget-boolean.component';
import { ListRendererWidgetToggleInputComponent } from './builders/list/list-renderer-widget-toggle-input/list-renderer-widget-toggle-input.component';
import { ListRendererWidgetIconTooltipComponent } from '@core/builders/list/list-renderer-widget-icon-tooltip/list-renderer-widget-icon-tooltip.component';
import { TooltipModule } from 'primeng/tooltip';
import { ListRendererWidgetProgressIconComponent } from './builders/list/list-renderer-widget-progress-icon/list-renderer-widget-progress-icon.component';
import { ListRendererWidgetMoveActionComponent } from './builders/list/list-renderer-widget-move-action/list-renderer-widget-move-action.component';
import { ListRendererWidgetIconByConditionComponent } from './builders/list/list-renderer-widget-icon-by-condition/list-renderer-widget-icon-by-condition.component';
import { ListRendererProductDescriptionResponsiveComponent } from './builders/list/list-renderer-product-description-responsive/list-renderer-product-description-responsive.component';
import { ListRendererWidgetOrderTypeIconComponent } from '@core/builders/list/list-renderer-widget-order-type-icon/list-renderer-widget-order-type-icon.component';

function onAuthError(): void {
  window.location.href = `auth/login`;
}

const BASE_WIDGETS = [
  ListRendererWidgetCurrencyComponent,
  ListRendererWidgetCheckboxComponent,
  ListRendererWidgetInputComponent,
  ListRendererWidgetDropdownComponent,
  ListRendererWidgetMinusPrefixComponent,
  ListRendererWidgetTextStyleByConditionComponent,
  ListRendererWidgetTextInputComponent,
  ListRendererWidgetIntegerComponent,
  ListRendererWidgetBooleanComponent,
  ListRendererWidgetIconTooltipComponent,
];

@NgModule({
  declarations: [
    ...BASE_WIDGETS,
    InputWidgetBaseComponent,
    TimeInputComponent,
    PriceInputComponent,
    ListRendererWidgetStatusIconComponent,
    AutocompleteCustomerComponent,
    ProductMultiselectDropdownComponent,
    ListRendererWidgetDateComponent,
    DateInputComponent,
    ListRendererWidgetToggleInputComponent,
    ListRendererWidgetProgressIconComponent,
    ListRendererWidgetMoveActionComponent,
    ListRendererWidgetIconByConditionComponent,
    ListRendererProductDescriptionResponsiveComponent,
    ListRendererWidgetOrderTypeIconComponent,
  ],
  exports: [...BASE_WIDGETS],
  imports: [
    CommonModule,
    CapturumCheckboxModule,
    CalendarModule,
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
      authRefreshToken: {
        useRefreshToken: true,
        offsetSeconds: 60,
        intervalSeconds: 3,
      },
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
      dateInterceptor: {
        dateProperties: API_DATE_PROPERTIES,
        blackListedURLs: API_DATE_BLACKLIST,
        blackListedStatusCodes: [401, 204],
        displayFormat: DatesFormats.displayFormat,
        sendFormat: DatesFormats.sendFormat,
      },
    }),
    LoginModule.forRoot({
      environment,
      productName: environment.appName,
      redirectAfterLogin: 'admin/manage',
      defaultLanguage: 'en',
      loginService: DeMooijLoginService,
    }),
    CompleteModule.forRoot(
      {
        indexedDbModels: [],
        databaseName: 'complete-db',
        version: 1,
        environment: environment,
      },
      NgxPermissionsService,
      NgxPermissionsModule,
    ),
    ReactiveFormsModule,
    CapturumInputModule,
    CapturumCalendarModule,
    CapturumDropdownModule,
    CapturumInputNumberModule,
    FormsModule,
    CapturumAutocompleteModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumMultiSelectModule,
    CapturumInfoTableModule,
    TooltipModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCodeInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {};
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule {}

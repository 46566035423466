import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ScreenSize } from '@core/enums/screen-size.enum';

@Injectable({
  providedIn: 'root',
})
export class BreakPointService {
  public screenSize$: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>(ScreenSize.desktop);

  constructor() {
    this.screenSize$.next(this.getScreenSize());
  }

  public getWindowWidth(): number {
    return Math.max(
      document?.body?.scrollWidth,
      document?.documentElement?.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth,
    );
  }

  /**
   *   Breakpoints
   *   Small	None	<576px
   *   Small	sm	≥576px
   *   Medium	md	≥768px
   *   Large	lg	≥992px
   *   Extra large	xl	≥1200px
   *   Extra extra large	xxl	≥1400px
   **/
  public getScreenSize(): ScreenSize {
    const width = this.getWindowWidth();

    if (width <= 576) {
      return ScreenSize.mobile;
    }

    if (width > 576 && width < 992) {
      return ScreenSize.tablet;
    }

    return ScreenSize.desktop;
  }
}

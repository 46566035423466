<div class="product-name">
  <ng-container *ngIf="item.client; else noClient">
    <span class="name">{{ orderNumber }}</span>
    <div>
      <span class="subtitle mr-2">{{ item.client }}</span>
      <span class="subtitle mr-2">{{ item.progress }}</span>
      <i class="subtitle mr-2" [class]="iconClass"></i>
      <i *ngIf="item.touched_icon && !item.item_progress" class="subtitle mr-2" [class]="'fas fa-eye'"></i>
    </div>
  </ng-container>

  <ng-template #noClient>
    <div>
      <span *ngIf="isGoods" class="name mr-2">{{ item.quantity }}</span>
      <span class="name">{{ title }}</span>
    </div>

    <div>
      <span *ngIf="subtitle" class="subtitle">{{ subtitle }}</span>
      <i *ngIf="isCompleted" class="subtitle ml-2" [class]="iconClass"></i>
    </div>

    <div>
      <span class="subtitle bold">{{ orderNumber }}</span>
      <i *ngIf="item.destination" class="fa-solid fa-arrow-right mr-2 ml-2"></i>
      <span *ngIf="item.destination" class="subtitle bold big">{{ item.destination }}</span>
    </div>

    <div *ngIf="isCompleted">
      <ng-container
        *ngTemplateOutlet="
          spotInfo;
          context: { spotName: item.spotProduct?.spot?.full_spot_name, icon: 'fa-arrow-left' }
        "></ng-container>
      <ng-container
        *ngTemplateOutlet="
          spotInfo;
          context: { spotName: item.spotActivity?.spot?.full_spot_name, icon: 'fa-arrow-right' }
        "></ng-container>
    </div>
  </ng-template>

  <ng-template #spotInfo let-spotName="spotName" let-icon="icon">
    <div *ngIf="spotName">
      <i class="fa-solid" [ngClass]="icon + ' mr-2 ml-2'"></i>
      <span class="subtitle bold big">{{ spotName }}</span>
    </div>
  </ng-template>
</div>

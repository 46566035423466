import { Injectable } from '@angular/core';
import { ActionOptions, BuilderApiService, ConfirmationBackendAction } from '@capturum/builders/core';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ListBackendConfirmationService extends ConfirmationBackendAction {
  constructor(
    protected readonly apiHttp: BuilderApiService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly translateService: TranslateService,
    private readonly store: Store,
  ) {
    super(apiHttp, confirmationService, translateService);
  }

  public execute(options: ActionOptions, item: any): Observable<any> {
    return super.execute(options, item).pipe(
      tap(() => {
        this.store.dispatch(new FetchActionsExecution(BuilderActionType.backendConfirmation));
      }),
    );
  }
}

import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService, ValidatorService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpCodeInterceptor implements HttpInterceptor {
  constructor(
    private readonly validatorService: ValidatorService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly toastNotificationService: ToastNotificationService,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (response: any) => {
          if (response?.body) {
            this.toastNotificationService.metaMessages(response.body);
          }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
              case 401:
                if (window?.location?.href.includes(AppRoutes.public)) {
                  this.router.navigate(['/', AppRoutes.expired]);
                } else {
                  this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
                }
                break;
              case 403: {
                const title = this.translateService.instant('demooij.toast.warning.title');

                this.toastService.error(title, error?.error?.message);
                break;
              }
              case 422:
                this.parseValidations(error.error);

                break;
              case 429: {
                const title = this.translateService.instant('demooij.toast.warning.title');

                this.toastService.warning(title, error?.error?.message);
                break;
              }
              default:
                break;
            }
          }
        },
      ),
    );
  }

  protected async parseValidations(responseData: {
    errors: Record<string, string[]>;
    message: string;
    status?: string;
  }): Promise<void> {
    let response = responseData;

    if (response instanceof Blob) {
      response = JSON.parse(await response.text());
    }

    const keys = response.errors && Object.keys(response.errors);
    let errors: string[] = [];

    if (keys?.length) {
      const fieldErrors = keys.reduce((acc, key) => {
        errors = [...errors, ...response.errors[key]];

        return { ...acc, [key]: response.errors[key] };
      }, {});

      this.validatorService.backendValidation.next(fieldErrors);
    }

    this.onNonFieldError(errors);

    if (responseData.status === 'completed') {
      this.router.navigate(['/', AppRoutes.admin, AppRoutes.warehouse, AppRoutes.orderpicking], {
        state: { completed: true },
      });
    }
  }

  protected onNonFieldError(errors: string[]): void {
    const title = this.translateService.instant('toast.error.title');

    this.toastService.error(title, errors.join('\n'), { life: 5000 });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitProductDescription',
})
export class SplitProductDescription implements PipeTransform {
  public transform(productDescription: string, field?: string): { title: string; subTitle: string } {
    const descriptionArray = productDescription.split(',');

    return {
      title: descriptionArray?.[0],
      subTitle: descriptionArray
        .splice(1, descriptionArray.length - 1)
        ?.map((part) => {
          return part.trim();
        })
        .join(', '),
    };
  }
}

import { Injectable } from '@angular/core';
import { Entity } from '@core/enums/entity.enum';
import { EntityAction } from '@core/enums/entity-action.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';

interface Meta {
  messages: Message[];
}

interface Message {
  text: string;
  type: Type;
}

enum Type {
  warning = 'warning',
  success = 'success',
  info = 'info',
}

@Injectable({
  providedIn: 'root',
})
export class ToastNotificationService {
  constructor(private toastService: ToastService, private translateService: TranslateService) {}

  public entityActionSuccess(entity: Entity | string, action: EntityAction, prefix = false): void {
    let enablePrefix = prefix;

    switch (action) {
      case EntityAction.add:
        enablePrefix = true;
        break;
      case EntityAction.update:
      case EntityAction.create:
        enablePrefix = false;
        break;
      default:
        break;
    }

    const message = this.translateService.instant(`${enablePrefix ? 'demooij.' : ''}toast.success.${action}`, {
      entity: entity
        ?.replace(/\b\w/g, (l) => {
          return l.toUpperCase();
        })
        .replace(/-/g, ' '),
    });

    this.success(message);
  }

  public success(message: string): void {
    const title = this.translateService.instant('toast.success.title');

    this.toastService.success(title, message);
  }

  public warning(message: string): void {
    const title = this.translateService.instant('demooij.toast.warning.title');

    this.toastService.warning(title, message);
  }

  public info(message: string): void {
    const title = this.translateService.instant('demooij.toast.info.title');

    this.toastService.info(title, message);
  }

  public error(message: string): void {
    const title = this.translateService.instant('toast.error.title');

    this.toastService.error(title, message);
  }

  public metaMessages(response: { meta: Meta; data?: object }): boolean {
    const messages = response?.meta?.messages;

    if (messages?.length) {
      messages.forEach(({ type, text }) => {
        let module = '';

        if (type === Type.warning) {
          module = 'demooij.';
        }

        const title = this.translateService.instant(`${module}toast.${type}.title`);

        this.toastService[type](title, text);
      });

      return true;
    }

    return false;
  }
}

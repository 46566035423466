import { InputFocussed, SetBackUrl, UpdateChangedItem } from '@store/general/general.actions';
import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { GeneralStateModel } from './general-state.model';
import {
  AdditionalPayloadForWidget,
  SaveListRenderData,
  SetActiveCompany,
  SetPageConfig,
  SetSubmittingStatus,
  UnloadingLocation,
} from './general.actions';

@State<GeneralStateModel>({
  name: 'general',
})
@Injectable()
export class GeneralState {
  @Action(SetSubmittingStatus)
  public setSubmitting(ctx: StateContext<GeneralStateModel>, { key, status }: SetSubmittingStatus): void {
    const state = ctx.getState();
    const submitting = { ...state?.submitting } ?? {};

    submitting[key] = status;

    ctx.patchState({
      submitting,
    });
  }

  @Action(SetActiveCompany)
  public setActiveCompany(ctx: StateContext<GeneralStateModel>, { company }: SetActiveCompany): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      company,
    });
  }

  @Action(UpdateChangedItem)
  public updateChangedItem(ctx: StateContext<GeneralStateModel>, { index, property, item }: UpdateChangedItem): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      item: { ...item, property, index },
    });
  }

  @Action(InputFocussed)
  public inputFocussed(ctx: StateContext<GeneralStateModel>, { rowIndex, fieldName }: InputFocussed): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      focusedRowIndex: rowIndex,
      focusedFieldName: fieldName,
    });
  }

  @Action(SetBackUrl)
  public setBackUrlToPageCOnfig(ctx: StateContext<GeneralStateModel>, { backUrl }: { backUrl: string }): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      pageConfig: {
        ...state.pageConfig,
        backUrl,
      },
    });
  }

  @Action(SetPageConfig)
  public setPageConfig(ctx: StateContext<GeneralStateModel>, { config }: SetPageConfig): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      pageConfig: config,
    });
  }

  @Action(UnloadingLocation)
  public setUnloadingLocation(ctx: StateContext<GeneralStateModel>, { unloadingLocation }: UnloadingLocation): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      unloadingLocation,
    });
  }

  @Action(AdditionalPayloadForWidget)
  public additionalPayloadForWidget(
    ctx: StateContext<GeneralStateModel>,
    { additionalPayload }: AdditionalPayloadForWidget,
  ): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      additionalPayload,
    });
  }

  @Action(SaveListRenderData)
  public setSavedListRenderData(ctx: StateContext<GeneralStateModel>, { data }: SaveListRenderData): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      listRenderData: [...(data || [])],
    });
  }
}

import { PublicSelectors } from '@store/public/public.selectors';
import { Select } from '@ngxs/store';
import { Customer } from '@store/public/public-state.model';
import { Observable } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PriceyService } from '@core/api/pricey-api.service';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit, OnDestroy {
  @Select(PublicSelectors.getCustomer) public customer$: Observable<Customer>;

  constructor(public priceyService: PriceyService) {}

  public ngOnInit(): void {
    this.priceyService.showLoader.next(false);
  }

  public ngOnDestroy(): void {
    // when we will go on private part we can remove token
    localStorage.removeItem('token');
  }
}

<div class="basic-layout desktop">
  <div class="basic-layout__sidebar" #sidebar>
    <cap-sidebar-menu
      logoUrl="/assets/images/logo.png"
      [title]="environment.appName"
      [expandable]="true"
      [isExpanded]="isExpanded"
      [tooltipDisabled]="isExpanded"
      [menus]="menus$ | async"
      (onExpand)="setExpandedStatus($event)">
    </cap-sidebar-menu>
  </div>

  <div class="basic-layout__page">
    <div
      class="basic-layout__header"
      [ngStyle]="{
        width: 'calc(100% - ' + panel?.offsetWidth + 'px)',
        'padding-left.px': sidebar?.offsetWidth
      }">
      <app-header [showUserMenu]="true"> </app-header>
    </div>

    <div
      class="basic-layout__content"
      [ngStyle]="{
        'padding-right.px': panel?.offsetWidth + padding,
        'padding-left.px': sidebar?.offsetWidth + padding
      }">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="basic-layout__panel" #panel>
    <app-panel></app-panel>
  </div>
</div>

<div class="page__header row no-gutters">
  <div [classList]="headingClass">
    <div class="page__heading">
      <ng-content select="[heading]"></ng-content>
    </div>
  </div>

  <div [classList]="centerClass">
    <div class="page__center">
      <ng-content select="[center]"></ng-content>
    </div>
  </div>

  <div [classList]="actionClass">
    <div class="page__actions">
      <ng-content select="[actions]"></ng-content>

      <span
        *ngIf="actionMenuList?.length || actionButtons?.length"
        class="dynamic-content"
        [class.action-menu]="menuRef?.isOpen">
        <ng-container *ngIf="!isMobile || actionMenuList.length === 1">
          <ng-container *ngFor="let button of actionButtons">
            <ng-container *ngIf="(button?.hidden | observablePipe | async | boolify) === false">
              <ng-container *ngxPermissionsOnly="button.permissions">
                <ng-container
                  *ngIf="
                    button?.type && button.type === ActionButtonType.splitButton && button.subAction?.length;
                    else simpleButton
                  ">
                  <p-splitButton
                    [label]="button.label | observablePipe | async"
                    [menuStyle]="{ width: 'auto' }"
                    [model]="button.subAction"
                    (onClick)="button.subAction[0].command()">
                  </p-splitButton>
                </ng-container>

                <ng-template #simpleButton>
                  <cap-button
                    appPreventDoubleClick
                    capButtonLoading
                    [attr.data-test]="button.label"
                    [debounceTime]="button.debounceTime"
                    [disabled]="(button.submitting | observablePipe | async) ?? button.disabled"
                    [icon]="(button.submitting | observablePipe | async) ? 'fas fa-spinner fa-pulse' : button.icon"
                    [label]="button.label | observablePipe | async"
                    [styleClass]="button.styleClass ?? 'primary'"
                    [submitting]="button.submitting | observablePipe | async"
                    (clickEvent)="doAction(button)">
                  </cap-button>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
        <span [hidden]="isMobile ? !mobileActionMenuList?.length : !actionMenuList?.length">
          <cap-action-menu
            #menuRef
            [actionItems]="isMobile ? mobileActionMenuList : actionMenuList"
            [hidden]="isMobile ? !mobileActionMenuList?.length : !actionMenuList?.length"
            [openPosition]="isMobile ? ActionMenuPosition.TopLeft : ActionMenuPosition.BottomLeft">
          </cap-action-menu>
        </span>
      </span>
    </div>
  </div>
</div>

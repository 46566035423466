import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { PanelBase } from '@core/classes/panel-base.class';
import { Entity } from '@core/enums/entity.enum';
import { PurchaseOrderService } from '@features/purchase-order/services/purchase-order.service';

interface PanelContent {
  context: Record<string, string | number>;
  key: string;
  title: string;
  entity?: Entity;
}

interface ListConfig extends PanelContent {
  priceFieldName: string;
  quantityFieldName: string;
  averagePrice?: number;
}

enum PriceFieldName {
  'sales-order' = 'purchaseOrderProduct.cost_price',
  'purchase-order' = 'salesOrderProduct.sales_price',
}

@Component({
  selector: 'app-product-allocation-details-panel',
  templateUrl: './product-allocation-details-panel.component.html',
  styleUrls: ['./product-allocation-details-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAllocationDetailsPanelComponent extends PanelBase implements AfterViewInit {
  public item: Record<string, string | number>;
  public Entity: typeof Entity = Entity;
  public content: PanelContent[];
  public listConfigs: ListConfig[];
  @ViewChild(CapturumListRendererComponent)
  public listRenderer: CapturumListRendererComponent;

  constructor(private cdr: ChangeDetectorRef, private purchaseOrderService: PurchaseOrderService) {
    super();
  }

  public ngAfterViewInit(): void {
    this.listConfigs = this.content?.map((config) => {
      const context: Record<string, string | number> = {};
      let priceFieldName: string;

      if (Object.entries(config?.context)?.length) {
        Object.entries(config.context).forEach(([key, value]) => {
          return (context[key] = this.item[value]);
        });
      }

      if (config.entity) {
        priceFieldName = PriceFieldName[config.entity];
      }

      return {
        ...config,
        context,
        priceFieldName,
        quantityFieldName: 'quantity',
      };
    });

    this.cdr.detectChanges();
  }

  public onListRendererDataChange(listConfig: ListConfig): void {
    if (!listConfig?.priceFieldName || !listConfig?.quantityFieldName || !this.listRenderer?.data?.length) {
      return;
    }

    if (listConfig.context.purchase_order_product_id) {
      this.purchaseOrderService
        .getAveragePrice(listConfig.context.purchase_order_product_id)
        .subscribe((response: { average_price_detail: number }) => {
          listConfig.averagePrice = response.average_price_detail;

          this.cdr.detectChanges();
        });

      return;
    }

    const totalQuantity = this.listRenderer.data.reduce((agg, item) => {
      const quantity = parseFloat(`${item[listConfig.quantityFieldName]}`.replace(',', '.'));

      if (quantity > 0) {
        return agg + quantity;
      }

      return agg;
    }, 0);

    const totalPrices = this.listRenderer.data.reduce((agg, item) => {
      if (item[listConfig.priceFieldName] && item[listConfig.quantityFieldName]) {
        return (
          agg +
          parseFloat(`${item[listConfig.priceFieldName]}`.replace(',', '.')) *
            Math.abs(parseFloat(`${item[listConfig.quantityFieldName]}`.replace(',', '.')))
        );
      }

      return agg;
    }, 0);
    const averagePrice = (totalPrices / totalQuantity).toFixed(2);

    listConfig.averagePrice = parseFloat(averagePrice);

    this.cdr.detectChanges();
  }
}

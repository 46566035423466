import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tab-layout-header',
  templateUrl: './tab-layout-header.component.html',
})
export class TabLayoutHeaderComponent {
  @Input() public headingClass: string | string[] = 'col-md-5';
  @Input() public actionClass: string | string[] = 'col-md-7';
}

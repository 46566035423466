import { Component } from '@angular/core';
import { PanelBase } from '@core/classes/panel-base.class';
import { Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-day-price-panel',
  templateUrl: './day-price-panel.component.html',
})
export class DayPricePanelComponent extends PanelBase {
  public item: any;
  public Entity: typeof Entity = Entity;
}

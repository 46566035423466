import { RouterUtil } from '@core/utils/router-util';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@capturum/auth';
import { AppRoutes } from '@core/enums/routes.enum';
import { User } from '@capturum/complete';
import { filter, first, map, startWith, takeUntil } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { DestroyBase } from '@capturum/shared';
import { from, merge, Observable } from 'rxjs';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/general/general.selectors';
import { PageConfig } from '@core/models/page-config.model';
import { ProfileUpdate, SetActiveCompany } from '@store/general/general.actions';
import { Company } from '@core/models/company.model';
import { CapturumUserMenuComponent } from '@capturum/ui/user-menu';
import { CompanyApiService } from '@core/api/company-api.service';
import { ConfirmationService } from 'primeng/api';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { CacheService } from '@core/services/cache.service';
import { PublicSelectors } from '@store/public/public.selectors';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';
import { Customer } from '@store/public/public-state.model';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends DestroyBase implements OnInit, AfterViewInit, OnDestroy {
  @Input() public showUserMenu: boolean;
  @Input() public logoUrl: string;
  @Input() public logoCenter: string;
  @Input() public isPublicPage: boolean;

  @Select(PublicSelectors.getCustomer)
  public customer$: Observable<Customer>;

  @Select(GeneralSelectors.getPageConfig)
  public pageConfig$: Observable<PageConfig>;

  @Select(GeneralSelectors.getActiveCompany)
  public activeCompany$: Observable<Company>;

  @ViewChild(CapturumUserMenuComponent)
  public userMenuComponent: CapturumUserMenuComponent;

  public isMobile: boolean = this.breakPointService.getScreenSize() !== ScreenSize.desktop;

  public isGemotraCompany: boolean;
  public menus: (MenuItem & { hidden?: boolean })[];
  public user$: Observable<User>;
  public isSalesOrders: boolean;
  public isGoods: boolean;
  public toShowBreadcrumb$: Observable<boolean>;
  public toShowMobileTitle$: Observable<boolean>;

  private managePermissions: string[] = [
    'user.manage.tenant',
    'tenant.manage',
    'module.manage.tenant',
    'translation.manage.tenant',
    'role.manage.tenant',
  ];

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly translateService: TranslateService,
    private readonly actions: Actions,
    private readonly store: Store,
    private readonly cacheService: CacheService,
    private readonly companyService: CompanyApiService,
    private readonly confirmationService: ConfirmationService,
    private readonly breakPointService: BreakPointService,
  ) {
    super();

    this.user$ = merge(
      this.authService.getAuthenticationState(),
      this.actions.pipe(ofActionSuccessful(ProfileUpdate)),
    ).pipe(
      map(() => {
        return this.authService.getUser();
      }),
    );

    this.toShowBreadcrumb$ = this.router.events.pipe(
      filter((events) => {
        return events instanceof NavigationEnd;
      }),
      takeUntil(this.destroy$),
      map((event: NavigationEnd) => {
        return ['stock-count', 'warehouse/warehouses'].some((urlPart) => {
          return event.url.includes(urlPart);
        });
      }),
    );

    this.toShowMobileTitle$ = this.router.events.pipe(
      filter((events) => {
        return events instanceof NavigationEnd;
      }),
      takeUntil(this.destroy$),
      map((event: NavigationEnd) => {
        return ['goods', 'transfer'].some((urlPart) => {
          return event.url.includes(urlPart);
        });
      }),
    );
  }

  public ngOnInit(): void {
    this.isGemotraCompany =
      this.store.selectSnapshot(PublicSelectors.getCustomer)?.companyCode === CompanyCodesEnum.gemotra;

    if (this.showUserMenu) {
      this.translateService.onLangChange
        .pipe(startWith(this.translateService.defaultLang), takeUntil(this.destroy$))
        .subscribe(() => {
          return this.populateMenus();
        });
    }

    this.user$.pipe(filter(Boolean), first(), takeUntil(this.destroy$)).subscribe(() => {
      if (!this.isPublicPage) {
        this.companyService.finishPeriodicalPolling();
        this.companyService.startCompanyPolling();
      }
    });

    this.isSalesOrders = this.router.url.includes('sales-order');
  }

  public ngOnDestroy(): void {
    if (!this.isPublicPage) {
      this.companyService.finishPeriodicalPolling();
    }
  }

  public ngAfterViewInit(): void {
    if (this.userMenuComponent?.panel) {
      this.userMenuComponent.panel.baseZIndex = 3; // because header have 2
    }
  }

  @HostListener('window:scroll', [])
  public onWindowScroll(event: MouseEvent): void {
    if (this.userMenuComponent?.panel?.render) {
      this.userMenuComponent.panel.hide();
    }
  }

  @HostListener('window:resize', [])
  public onWindowResize(event: MouseEvent): void {
    this.isMobile = this.breakPointService.getScreenSize() !== ScreenSize.desktop;
  }

  public onHeaderActionClick(): void {
    this.userMenuComponent.panel.hide();
  }

  public goBack(config: PageConfig): void {
    if (config.warnBeforeRedirect && this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData)?.length) {
      this.confirmationService.confirm({
        message: this.translateService.instant('demooij.back-to-overview.data-lost'),
        accept: () => {
          this.redirectToBackPage(config);
        },
      });

      return;
    }

    this.redirectToBackPage(config);
  }

  private redirectToBackPage(config: PageConfig): void {
    const returnUrl = RouterUtil.getReturnUrl(this.router.url);
    const backUrl = returnUrl?.url || config.backUrl;

    if (returnUrl?.queryParams) {
      this.router.navigate([returnUrl.url], {
        queryParams: returnUrl.queryParams,
      });

      return;
    }

    this.router.navigateByUrl(backUrl);
  }

  private populateMenus(): void {
    from(this.ngxPermissionsService.hasPermission(this.managePermissions))
      .pipe(first())
      .subscribe((canManage) => {
        this.menus = [
          {
            label: this.translateService.instant('link.profile'),
            icon: 'fas fa-user-circle',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`,
          },
          {
            label: this.translateService.instant('config.manage.title'),
            icon: 'fas fa-cog',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.config}`,
            hidden: !canManage,
          },
          {
            label: this.translateService.instant('demooij.link.reload-app'),
            icon: 'fas fa-sync',
            command: () => {
              this.cacheService.clearCache(true);
            },
          },
          {
            label: this.translateService.instant('demooij.btn.show-user-activities'),
            icon: 'fas fa-list-timeline',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.userActivity}`,
            hidden: !this.ngxPermissionsService.getPermission('user-activity.view'),
          },
          {
            label: this.translateService.instant('link.logout'),
            icon: 'fas fa-sign-in-alt',
            command: () => {
              this.authService.logout().subscribe(() => {
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]).then(() => {
                  this.store.dispatch(new SetActiveCompany(null));
                });
              });
            },
          },
        ];
      });
  }
}

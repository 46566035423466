import { Component, OnDestroy, OnInit } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { InterpolationPipe } from '@capturum/ui/api';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiHttpService, ApiSingleResult } from '@capturum/api';
import { UpdateChangedItem } from '@store/general/general.actions';
import { Store } from '@ngxs/store';
import { CapturumBuildersContextService } from '@capturum/builders/core';

@Component({
  selector: 'app-list-renderer-widget-checkbox',
  template: ` <cap-checkbox [binary]="binary" [formControl]="control" [disabled]="disabled"> </cap-checkbox>`,
  styleUrls: ['./list-renderer-widget-checkbox.component.scss'],
  providers: [InterpolationPipe],
})
export class ListRendererWidgetCheckboxComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public disabled = false;
  public binary = true;
  public endpoint: {
    method: string;
    url: string;
    payload?: Record<string, string>;
  };

  public control: UntypedFormControl = new UntypedFormControl();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiHttp: ApiHttpService,
    private readonly interpolationPipe: InterpolationPipe,
    private readonly store: Store,
    private readonly contextService: CapturumBuildersContextService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.control.setValue(!!this.item[this.field]);
    this.control.valueChanges.pipe(takeUntil(this.destroy$))?.subscribe(() => {
      return this.save();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public save(): void {
    const url = this.interpolationPipe.transform(this.endpoint?.url, this.item);
    const payload = Object.keys(this.endpoint.payload ?? {}).reduce((acc, key) => {
      acc[key] = this.interpolationPipe.transform(`{{${this.endpoint.payload[key]}}}`, this.item);

      return acc;
    }, {});

    const body = { [this.field]: this.control.value, ...payload };

    if (
      this.field === 'has_fixed_price' &&
      this.contextService.getContextByKey('list_supplier-product-price')?.location
    ) {
      body['location'] = this.contextService.getContextByKey('list_supplier-product-price')?.location;
    }

    this.apiHttp[this.endpoint?.method?.toLocaleLowerCase()](url, body)?.subscribe((response: ApiSingleResult<any>) => {
      this.store.dispatch(new UpdateChangedItem(this.field, this.index, response.data));
    });
  }
}

<div class="panel" [class.panel--active]="isOpen">
  <div class="panel__header">
    <div class="panel__title">
      {{ title ?? config?.title }}
    </div>

    <div class="panel__close">
      <i class="fas fa-times" (click)="close()"></i>
    </div>
  </div>

  <div class="panel__wrapper">
    <div class="panel__content">
      <app-dynamic-component *ngIf="config" [component]="components[config.component]" [options]="config?.options">
      </app-dynamic-component>
    </div>

    <div class="panel__footer">
      <cap-button
        styleClass="primary"
        [label]="'button.close' | translate"
        [attr.data-test]="'cancel-panel-btn'"
        (onClick)="close()">
      </cap-button>
    </div>
  </div>
</div>

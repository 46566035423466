import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitAtComma',
})
export class SplitAtCommaPipe implements PipeTransform {
  public transform(value: string, part: 1 | 2 = 1): string {
    if (!value) {
      return value;
    }

    const index = value.indexOf(',');

    if (index === -1) {
      return value;
    }

    if (part === 1) {
      return value.slice(0, index).trim();
    } else {
      return value.slice(index + 1).trim();
    }
  }
}

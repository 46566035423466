import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { InterpolationPipe, MapItem } from '@capturum/ui/api';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ApiHttpService, ApiListResult, ApiSingleResult } from '@capturum/api';
import { Store } from '@ngxs/store';
import { UpdateChangedItem } from '@store/general/general.actions';
import { ConverterHelper } from '@capturum/shared';

@Component({
  selector: 'app-list-renderer-widget-dropdown',
  template: ` <cap-dropdown
    [appendTo]="'body'"
    [disabled]="disabled"
    [formControl]="control"
    [options]="listOptions"
    [filter]="true">
  </cap-dropdown>`,
})
export class ListRendererWidgetDropdownComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public endpoint: {
    method: string;
    url: string;
    payload?: Record<string, string>;
  };

  /**  Endpoint for extracting dropdown items **/
  public data: string;
  public disabled = false;
  public control: UntypedFormControl = new UntypedFormControl();
  public items: [];
  public listOptions: MapItem[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiHttp: ApiHttpService,
    private readonly interpolationPipe: InterpolationPipe,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.extractDropdownItems();
    this.control.setValue(this.item[this.field]);

    this.control.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(555))?.subscribe(() => {
      return this.save();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public save(): void {
    const url = this.interpolationPipe.transform(this.endpoint?.url, this.item);
    const payload = Object.keys(this.endpoint?.payload ?? {}).reduce((acc, key) => {
      acc[key] = this.interpolationPipe.transform(`{{${this.endpoint.payload[key]}}}`, this.item);

      return acc;
    }, {});

    this.apiHttp[this.endpoint?.method?.toLocaleLowerCase()](url, {
      [this.field]: this.control.value,
      ...payload,
    })?.subscribe((response: ApiSingleResult<any>) => {
      this.store.dispatch(new UpdateChangedItem(this.field, this.index, response.data));
    });
  }

  private extractDropdownItems(): void {
    if (this.items) {
      this.listOptions = ConverterHelper.listToOption(this.items);

      return;
    }

    const url = this.interpolationPipe.transform(this.data, this.item);

    this.apiHttp
      .get<ApiListResult>(url)
      .pipe(
        map((result) => {
          return ConverterHelper.listToOption(result.data);
        }),
      )
      .subscribe((items) => {
        this.listOptions = items;
        this.cdr.detectChanges();
      });
  }
}

import { Component, Input } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-list-renderer-widget-boolean',
  template: `
    <p [ngClass]="{ 'font-weight-bold': item?.show_bold }">
      {{ (!!item[field] ? 'general.yes' : 'general.no') | translate }}
    </p>
  `,
})
export class ListRendererWidgetBooleanComponent extends ListRendererWidgetBase {
  @Input() public default = '-';
  public value: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'formGroup',
})
export class FormGroupPipe implements PipeTransform {
  public transform(formGroupName: string, form: UntypedFormGroup): AbstractControl | null {
    return form ? form.get(formGroupName) : null;
  }
}

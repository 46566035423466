<div class="table-name">
  <div class="separator"></div>
  <div class="name">
    <div class="total-number" *ngIf="totalItemsNumber">
      {{ totalItemsNumber }}
    </div>

    <span [class.additional-data]="totalItemsNumber">{{ title | translate }}</span>
  </div>
  <div class="separator"></div>

  <div class="total-quantity" *ngIf="additionalInfo >= 0">
    {{ additionalInfo }}
  </div>
</div>

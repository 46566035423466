import { Injectable } from '@angular/core';
import { IndexedDbService } from '@capturum/complete';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  constructor(
    private readonly ngxPermissionService: NgxPermissionsService,
    private readonly indexedDbService: IndexedDbService,
    private readonly translateService: TranslateService,
  ) {}

  public clearCache(reloadPage = true): void {
    // Clear storage
    localStorage.clear();
    sessionStorage.clear();

    // Clear Permissions
    this.ngxPermissionService.flushPermissions();

    // Clear languages
    this.translateService.getLangs().forEach((language) => {
      this.translateService.resetLang(language);
    });

    // Clear indexDb tables
    this.indexedDbService.resetStore().then(() => {
      // Refresh and get redirect to login page
      if (reloadPage) {
        location.reload();
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { HeaderAction } from '@core/interfaces/header-action.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderActionsService {
  public headerActions$ = new BehaviorSubject<HeaderAction[]>([]);

  public setHeaderActions(actions: HeaderAction[]): void {
    this.headerActions$.next(actions);
  }
}

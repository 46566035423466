<cap-input-number
  class="price-input"
  [ngClass]="{ readonly: to?.readonly }"
  [formControl]="formControl"
  [prefix]="focused ? '' : to?.configurationOptions?.prefix"
  [minFractionDigits]="fractionDigits"
  [maxFractionDigits]="fractionDigits"
  (blur)="setFocus(false)"
  (focus)="setFocus(true)"
  (keyup.Enter)="setFocus(false)">
</cap-input-number>

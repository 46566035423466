import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sum',
})
export class SumPipe implements PipeTransform {
  public transform(items: Record<string, string>[], field: string): number {
    return items.reduce((sum: number, currentItem: Record<string, string>): number => {
      return sum + Number(currentItem[field]);
    }, 0);
  }
}

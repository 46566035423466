import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ToastService } from '@capturum/ui/api';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { DestroyBase } from '@capturum/shared';
import { CompanyApiService } from '@core/api/company-api.service';
import { Company } from '@core/models/company.model';
import { GeneralSelectors } from '@store/general/general.selectors';
import { SetActiveCompany } from '@store/general/general.actions';
import { AuthService } from '@capturum/auth';
import { COMPANY_CHANGE_BROADCAST_CHANNEL } from '@core/constants/company_change_broadcast_channel.constant';

@Component({
  selector: 'app-switch-company-list',
  templateUrl: './switch-company-list.component.html',
  styleUrls: ['./switch-company-list.component.scss'],
})
export class SwitchCompanyListComponent extends DestroyBase implements OnInit {
  // eslint-disable-next-line
  @Output() public onChange: EventEmitter<Company> = new EventEmitter();

  @Select(GeneralSelectors.getActiveCompany)
  public activeCompany$: Observable<Company>;

  public searchControl: UntypedFormControl = new UntypedFormControl();
  public companies$: Observable<Company[]>;
  public readonly channel: BroadcastChannel;

  constructor(
    private readonly companyService: CompanyApiService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly authService: AuthService,
  ) {
    super();

    try {
      this.channel = new BroadcastChannel(COMPANY_CHANGE_BROADCAST_CHANNEL);
    } catch (e) {
      // continue regardless of error
    }
  }

  public ngOnInit(): void {
    this.companies$ = this.getCompanies();
  }

  public changeCompany(company: Company): void {
    const activeCompanyId = this.store.selectSnapshot(GeneralSelectors.getActiveCompany)?.id;

    if (company.id !== activeCompanyId) {
      this.companyService
        .setActive(company.id)
        .pipe(
          switchMap(() => {
            return this.authService.patchUser({ current_company_id: company?.id });
          }),
        )
        .subscribe(() => {
          const activeCompany = this.companyService.companiesList.find((item) => {
            return item?.id === company?.id;
          });

          this.store.dispatch(new SetActiveCompany(activeCompany));

          this.toastService.success(
            this.translateService.instant('toast.success.title'),
            this.translateService.instant('demooij.company-switch.success'),
          );

          if (this.channel) {
            this.channel.postMessage(activeCompany);
          }

          this.onChange.emit(activeCompany);
        });
    }
  }

  private getCompanies(): Observable<Company[]> {
    return of(this.companyService.companiesList);
  }
}

import { MobileMenuComponent } from './shared/modules/layout/components/mobile-menu/mobile-menu.component';
import { ExpiredLinkComponent } from '@shared/modules/layout/components/expired-link/expired-link.component';
import { PublicCustomerResolver } from '@core/resolvers/public-customer.resolver';
import { PublicLayoutComponent } from '@shared/modules/layout/components/public-layout/public-layout.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import {
  BaseDataResolver,
  IndexedDbGuard,
  ModuleResolver,
  PermissionGuard,
  PublicTranslationResolver,
  SettingResolver,
  TranslationResolver,
} from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { PageConfigResolver } from '@core/resolvers/page-config.resolver';
import { AppRoutes } from '@core/enums/routes.enum';
import { CompanyResolver } from '@core/resolvers/company.resolver';
import { Entity } from '@core/enums/entity.enum';

const routes: Routes = [
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
    children: [...loginRoutes[0].children],
  },
  {
    path: AppRoutes.public,
    component: PublicLayoutComponent,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
      publicCustomer: PublicCustomerResolver,
    },
    children: [
      {
        path: AppRoutes.pricey,
        loadChildren: () => {
          return import('./public/pricey/pricey.module').then((m) => {
            return m.PriceyModule;
          });
        },
      },
    ],
  },
  {
    path: AppRoutes.expired,
    component: ExpiredLinkComponent,
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, PermissionGuard],
    resolve: {
      translations: TranslationResolver,
      baseData: BaseDataResolver,
      settings: SettingResolver,
      modules: ModuleResolver,
      company: CompanyResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'admin/manage',
        pathMatch: 'full',
      },
      {
        path: AppRoutes.menu,
        component: MobileMenuComponent,
      },
      {
        path: AppRoutes.admin,
        component: BasicLayoutComponent,
        children: [
          {
            path: AppRoutes.purchaseOrder,
            loadChildren: () => {
              return import('./features/purchase-order/purchase-order.module').then((m) => {
                return m.PurchaseOrderModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['purchase-order.view'],
              },
            },
          },
          {
            path: AppRoutes.salesOrder,
            loadChildren: () => {
              return import('./features/sales-order/sales-order.module').then((m) => {
                return m.SalesOrderModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['sales-order.view'],
              },
            },
          },
          {
            path: AppRoutes.tenant,
            loadChildren: () => {
              return import('./features/tenant/tenant.module').then((m) => {
                return m.TenantModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'tenant.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.role,
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.translation,
            loadChildren: () => {
              return import('./features/translation/translation.module').then((m) => {
                return m.TranslationModule;
              });
            },
          },
          {
            path: AppRoutes.user,
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: AppRoutes.baseData,
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then((m) => {
                return m.BaseDataModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: AppRoutes.setting,
            loadChildren: () => {
              return import('./features/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
          },
          {
            path: AppRoutes.builder,
            loadChildren: () => {
              return import('./features/builders/builders.module').then((m) => {
                return m.BuildersModule;
              });
            },
          },
          {
            path: AppRoutes.manage,
            loadChildren: () => {
              return import('./features/manage/manage.module').then((m) => {
                return m.ManageModule;
              });
            },
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                only: [],
              },
            },
          },
          {
            path: AppRoutes.warehouse,
            loadChildren: () => {
              return import('./features/warehouse/warehouse.module').then((m) => {
                return m.WarehouseModule;
              });
            },
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                only: ['warehouse.view'],
              },
            },
          },
          {
            path: AppRoutes.stockCount,
            loadChildren: () => {
              return import('./features/stock-count/stock-count.module').then((m) => {
                return m.StockCountModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['stock-count.view'],
              },
            },
          },
          {
            path: AppRoutes.dayPrice,
            loadChildren: () => {
              return import('./features/day-price/day-price.module').then((m) => {
                return m.DayPriceModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['day-price.view'],
              },
            },
          },
          {
            path: AppRoutes.supplierPrice,
            loadChildren: () => {
              return import('./features/supplier-price/supplier-price.module').then((m) => {
                return m.SupplierPriceModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['supplier-product-price.view'],
              },
            },
          },
          {
            path: AppRoutes.stock,
            loadChildren: () => {
              return import('./features/stock/stock.module').then((m) => {
                return m.StockModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['stock.view'],
              },
            },
          },
          {
            path: AppRoutes.forecast,
            loadComponent: () => {
              return import('./features/forecast/forecast.component').then((c) => {
                return c.ForecastComponent;
              });
            },
            canActivate: [NgxPermissionsGuard],
            resolve: {
              pageConfig: PageConfigResolver,
            },
            data: {
              pageConfig: {
                title: `demooij.entity-name.${Entity.forecast}.singular`,
              },
              permissions: {
                only: ['forecast.view'],
              },
            },
          },
          {
            path: AppRoutes.transport,
            loadChildren: () => {
              return import('./features/transport/transport.module').then((m) => {
                return m.TransportModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['logistic.order.view', 'logistic.order.manage', 'logistic.ride.view', 'logistic.ride.manage'],
              },
            },
          },
          {
            path: AppRoutes.goods,
            loadChildren: () => {
              return import('./features/goods/goods.module').then((m) => {
                return m.GoodsModule;
              });
            },
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: ['goods.receive.view', 'goods.receive.manage', 'goods.release.view', 'goods.release.manage'],
              },
            },
          },
          {
            path: '',
            loadChildren: () => {
              return import('@capturum/complete').then((complete) => {
                return complete.CompleteModule;
              });
            },
            resolve: {
              pageConfig: PageConfigResolver,
            },
            data: {
              pageConfig: {
                title: 'config.manage.title',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: `${AppRoutes.admin}/${AppRoutes.manage}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { ScreenSize } from '@core/enums/screen-size.enum';
import { BreakPointService } from './../../../../services/breakpoint.service';
import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { DestroyBase } from '@capturum/shared';
import { filter, takeUntil } from 'rxjs/operators';
import { SidebarMenuItem } from '@capturum/ui/api';
import { LayoutUtilsService } from '@shared/modules/layout/services/layout-utils.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { BaseDataKeyService, CompleteConfigService } from '@capturum/complete';
import { AppRoutes } from '@core/enums/routes.enum';
import { SetActiveCompany } from '@store/general/general.actions';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BasicLayoutComponent extends DestroyBase implements OnInit, AfterViewInit {
  public menus$: Observable<SidebarMenuItem[]>;
  public isExpanded = true;
  public padding = 44;
  public environment = environment;

  constructor(
    private readonly infoTableConfigService: InfoTableConfigService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly layoutUtilsService: LayoutUtilsService,
    private readonly configService: CompleteConfigService,
    private readonly baseDataKeyService: BaseDataKeyService,
    private readonly actions: Actions,
    private readonly router: Router,
    private readonly breakPointService: BreakPointService,
  ) {
    super();
    this.menus$ = this.layoutUtilsService.menus$;
    this.isExpanded = localStorage.getItem('sidebar') ? localStorage.getItem('sidebar') === 'true' : true;

    this.breakPointService.screenSize$.pipe(takeUntil(this.destroy$)).subscribe((size) => {
      this.padding = size === ScreenSize.desktop ? 44 : 22;
    });
  }

  public ngOnInit(): void {
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
    this.setTranslations();
    this.updateInfoTableConfig();
    this.addBuildersTileInManage();
    this.activeCompanyListener();
  }

  public ngAfterViewInit(): void {
    const user = this.authService.getUser();

    if (user) {
      Sentry.setUser({
        id: user.id.toString(),
        username: user.name,
        email: user.email,
      });
    }
  }

  public setExpandedStatus(expanded: boolean): void {
    this.isExpanded = expanded;
    localStorage.setItem('sidebar', JSON.stringify(this.isExpanded));

    setTimeout(() => {
      document?.body?.click();
    }, 500);
  }

  private updateInfoTableConfig(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.infoTableConfigService.setConfig({
        texts: {
          positive: this.translateService.instant('general.yes'),
          negative: this.translateService.instant('general.no'),
        },
        defaultTexts: {
          filtersLabel: this.translateService.instant('table.filters'),
          resetSortingLabel: this.translateService.instant('table.reset-sorting'),
          descendingLabel: this.translateService.instant('table.descending'),
          ascendingLabel: this.translateService.instant('table.ascending'),
          sortButtonLabel: this.translateService.instant('table.sort-data'),
          noResults: this.translateService.instant('table.no_results'),
          footerPaginationText: this.translateService.instant('table.paginator.text'),
          filtersEditHeader: this.translateService.instant('table.edit-filters.header'),
          filtersEditCloseBtn: this.translateService.instant('table.edit-filters.close'),
          filtersEditSelectItemsMessage: this.translateService.instant('table.edit-filters.message'),
          itemsSelectedMessage: '',
          resetFilterButtonLabel: '',
        },
      });
    });
  }

  private setTranslations(): void {
    this.translateService.addLangs(['nl', 'en']);
    this.translateService.setDefaultLang('en');

    this.authService
      .getAuthenticationState()
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(() => {
        this.translateService.use(this.authService.getUser()?.locale?.code);
      });
  }

  private activeCompanyListener(): void {
    this.actions
      .pipe(ofActionSuccessful(SetActiveCompany))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
        this.router.onSameUrlNavigation = 'reload';

        this.router.navigateByUrl(this.router.url).then(() => {
          this.router.onSameUrlNavigation = 'ignore';
          this.router.routeReuseStrategy.shouldReuseRoute = (
            future: ActivatedRouteSnapshot,
            curr: ActivatedRouteSnapshot,
          ) => {
            return future.routeConfig === curr.routeConfig;
          };
        });
      });
  }

  private addBuildersTileInManage(): void {
    this.configService.setAdditionalConfigOverviewItems([
      {
        titleTranslationKey: 'demooij.tiles.builder-configuration',
        iconClass: 'fas fa-cog',
        permission: 'builders.manage',
        routerLink: `/${AppRoutes.admin}/${AppRoutes.builder}/${AppRoutes.configuration}`,
      },
      {
        titleTranslationKey: 'demooij.tiles.builder-releases',
        iconClass: 'fas fa-list-ul',
        permission: 'builders.manage',
        routerLink: `/${AppRoutes.admin}/${AppRoutes.builder}/${AppRoutes.release}`,
      },
    ]);
  }
}

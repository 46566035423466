import { ActionMenuItem } from '@capturum/ui/action-menu';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';

export interface ActionButton extends Partial<ActionMenuItem> {
  styleClass?: string;
  permissions?: string[];
  debounceTime?: number;
  submitting?: boolean | Observable<boolean>;
  type?: ActionButtonType;
  subAction?: SubAction[];
  hideOnMobile?: boolean;
}

export enum ActionButtonType {
  button = 'button',
  splitButton = 'split-button',
}

export interface SubAction extends MenuItem {
  hideOnMobile?: boolean;
}

export class DateUtil {
  public static isBiggerOrEqual(date1: string, date2: string): boolean {
    return new Date(date1) >= new Date(date2);
  }

  public static isBigger(date1: string, date2: string): boolean {
    return new Date(date1) > new Date(date2);
  }

  public static isEqual(date1: string, date2: string): boolean {
    return new Date(date1).toDateString() === new Date(date2).toDateString();
  }
}

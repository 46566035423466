import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { HeaderComponent } from '@shared/modules/layout/components/header/header.component';
import { RouterModule } from '@angular/router';
import { CompleteModule } from '@capturum/complete';
import { SharedModule } from '@shared/shared.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SwitchCompanyListComponent } from './components/switch-company-list/switch-company-list.component';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { CapturumInputModule } from '@capturum/ui/input';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumUserMenuModule } from '@capturum/ui/user-menu';
import { CapturumSidebarMenuModule } from '@capturum/ui/sidebar-menu';
import { LayoutUtilsService } from '@shared/modules/layout/services/layout-utils.service';
import { PanelComponent } from './components/panel/panel.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import { DynamicComponentComponent } from './components/dynamic-component/dynamic-component.component';
import { ComponentHostDirective } from './directives/component-host.directive';
import { PublicLayoutComponent } from './components/public-layout/public-layout.component';
import { ExpiredLinkComponent } from './components/expired-link/expired-link.component';
import { GenericPanelContentComponent } from './components/generic-panel-content/generic-panel-content.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { BreadcrumbComponent } from '@shared/modules/layout/components/breadcrumb/breadcrumb.component';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { HeaderActionsComponent } from './components/header-actions/header-actions.component';

const BASE_LAYOUTS = [BasicLayoutComponent, PublicLayoutComponent];

@NgModule({
  declarations: [
    HeaderComponent,
    ...BASE_LAYOUTS,
    SwitchCompanyListComponent,
    DynamicComponentComponent,
    ComponentHostDirective,
    PanelComponent,
    ExpiredLinkComponent,
    GenericPanelContentComponent,
    MobileMenuComponent,
    BreadcrumbComponent,
    HeaderActionsComponent,
  ],
  exports: [...BASE_LAYOUTS],
  imports: [
    CommonModule,
    RouterModule,
    CompleteModule,
    SharedModule,
    OverlayPanelModule,
    CapturumSkeletonModule,
    CapturumInputModule,
    ReactiveFormsModule,
    CapturumUserMenuModule,
    CapturumSidebarMenuModule,
    CapturumButtonModule,
    CapturumListRendererModule,
    CapturumInfoTableModule,
  ],
  providers: [LayoutUtilsService],
})
export class LayoutModule {}

import { ScreenSize } from '@core/enums/screen-size.enum';
import { BreakPointService } from './../../../../services/breakpoint.service';
import { SetActiveCompany, SetPageConfig } from '@store/general/general.actions';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { AuthService } from '@capturum/auth';
import { SidebarMenuItem } from '@capturum/ui/api';
import { LayoutUtilsService } from '@shared/modules/layout/services/layout-utils.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  public menus$: Observable<(SidebarMenuItem & { permissions?: string[] })[]>;

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private breakPointService: BreakPointService,
  ) {}

  @HostListener('window:resize', [])
  public onWindowScroll(event: MouseEvent): void {
    setTimeout(() => {
      if (this.breakPointService.getScreenSize() === ScreenSize.desktop) {
        this.router.navigate([AppRoutes.admin, AppRoutes.manage]);
      }
    }, 150);
  }

  public ngOnInit(): void {
    this.store.dispatch(new SetPageConfig(null));
    this.menus$ = this.layoutUtilsService.menus$;
  }

  public logout(): void {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]).then(() => {
        this.store.dispatch(new SetActiveCompany(null));
      });
    });
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-list-renderer-widget-icon-tooltip',
  templateUrl: './list-renderer-widget-icon-tooltip.component.html',
  styleUrls: ['./list-renderer-widget-icon-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListRendererWidgetIconTooltipComponent extends ListRendererWidgetBase implements OnInit {
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  public isMobile: boolean;

  constructor(protected readonly breakPointService: BreakPointService) {
    super();
  }

  public ngOnInit(): void {
    this.isMobile = this.breakPointService.getScreenSize() !== ScreenSize.desktop;
  }
}

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-order-products-warning',
  templateUrl: './order-products-warning.component.html',
})
export class OrderProductsWarningComponent {
  constructor(public readonly ref: DynamicDialogRef) {}
}

import { ScreenSize } from './../enums/screen-size.enum';
import { BreakPointService } from './../../shared/services/breakpoint.service';
import { SetActiveCompany } from '@store/general/general.actions';
import { Store } from '@ngxs/store';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginConfig, LoginService } from '@capturum/login';
import { Injectable } from '@angular/core';
import { CompanyApiService } from '../api/company-api.service';
import { User } from '@core/models/user.model';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class DeMooijLoginService extends LoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly companyApiService: CompanyApiService,
    protected readonly store: Store,
    protected readonly breakPointService: BreakPointService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);
  }

  public onLoginSuccess(response: { user: User }): void {
    const size = this.breakPointService.getScreenSize();

    if (size === ScreenSize.desktop) {
      this.router.navigateByUrl(`${AppRoutes.admin}/${AppRoutes.manage}`);
    } else {
      this.router.navigateByUrl(
        response.user.currentRole.key.includes('order_picker') ? 'admin/warehouse/orderpicking' : 'menu',
      ); // Mobile or Tablet
    }

    this.companyApiService.get(response.user.current_company_id).subscribe((company) => {
      this.store.dispatch(new SetActiveCompany(company));
    });
  }
}

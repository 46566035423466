import { Component } from '@angular/core';
import { PanelBase } from '@core/classes/panel-base.class';
import { Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-sales-order-product-panel',
  templateUrl: './sales-order-product-panel.component.html',
  styleUrls: ['./sales-order-product-panel.component.scss'],
})
export class SalesOrderProductPanelComponent extends PanelBase {
  public item: any;
  public Entity: typeof Entity = Entity;
}

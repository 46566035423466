import { Injectable } from '@angular/core';
import { ApiService, ListOptions, ApiIndexResult } from '@capturum/api';
import { responseData } from '@capturum/builders/core';
import { MapItem } from '@capturum/ui/api';
import { Product } from '@core/models/entity/product.model';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PublicPriceyService extends ApiService<Product> {
  protected endpoint = 'pricey';

  public getPublicProductList(options?: ListOptions): Observable<ApiIndexResult<Product>> {
    let url = `/${this.endpoint}/product-list`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiIndexResult<Product>>(url);
  }

  public getMainGroupOptions(): Observable<MapItem[]> {
    const url = `/${this.endpoint}/builder/list/list_customer-product-public/input/productGroup.mainGroup.id`;

    return this.apiHttp.get(url).pipe(
      responseData,
      map((items) => {
        return items.map((item) => {
          return {
            ...item,
            value: item.key,
          };
        });
      }),
    );
  }
}

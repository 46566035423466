import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { Store } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';

@Injectable({
  providedIn: 'root',
})
export class SelectRowService implements ActionProvider {
  constructor(private readonly store: Store) {}

  public execute(options: ActionOptions, item: any): void {
    this.store.dispatch(new FetchActionsExecution(BuilderActionType.selectRow, { options, item }));
  }
}

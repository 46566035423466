import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon',
})
export class IconPipe implements PipeTransform {
  public transform(value: boolean | null): string {
    if (value === null) {
      return '';
    }

    return value ? 'fas fa-check button-check' : 'fas fa-times button-times';
  }
}

import { Injectable } from '@angular/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { SidebarMenuItem } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';
import { Select } from '@ngxs/store';
import { GeneralSelectors } from '@store/general/general.selectors';
import { Observable } from 'rxjs';
import { Company } from '@core/models/company.model';
import { map } from 'rxjs/operators';

@Injectable()
export class LayoutUtilsService {
  @Select(GeneralSelectors.getActiveCompany)
  public activeCompany$: Observable<Company>;

  public menus$: Observable<(SidebarMenuItem & { permissions?: string[] })[]>;

  private isMobile = this.breakPointService.getScreenSize() === ScreenSize.mobile;

  constructor(
    private readonly translateService: TranslateService,
    private readonly ngxPermissionService: NgxPermissionsService,
    private breakPointService: BreakPointService,
  ) {
    this.populateSidebarItems();
  }

  private populateSidebarItems(): void {
    this.menus$ = this.activeCompany$.pipe(
      map((company) => {
        const isGemotraCompany = company?.code === CompanyCodesEnum.gemotra;
        const isCashCarryCompany = company?.code === CompanyCodesEnum.cashcarry;

        return [
          {
            label: this.translateService.stream(`demooij.entity-name.forecast.singular`),
            routerLink: `${AppRoutes.admin}/${AppRoutes.forecast}`,
            icon: 'fas fa-chart-bar',
            permissions: ['forecast.view'],
            hidden: this.isMobile,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.supplier-prices'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.supplierPrice}`,
            icon: 'fas fa-coins',
            permissions: ['supplier-product-price.view'],
            hidden: this.isMobile || isGemotraCompany,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.day-prices'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.dayPrice}`,
            icon: 'fas fa-calendar-day',
            permissions: ['day-price.view'],
            hidden: this.isMobile,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.purchase-orders'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.purchaseOrder}`,
            icon: 'fas fa-receipt',
            permissions: ['purchase-order.view'],
            hidden: this.isMobile || isGemotraCompany,
          },
          {
            label: this.translateService.stream('demooij.list.title.stock-count'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.stockCount}/no-location`,
            icon: 'fas fa-list-ol',
            permissions: ['stock-count.view'],
            hidden: isGemotraCompany,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.stock'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.stock}`,
            icon: 'fas fa-boxes-alt',
            permissions: ['stock.view'],
            hidden: this.isMobile || isGemotraCompany,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.sales-orders'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.salesOrder}`,
            icon: 'fas fa-store',
            permissions: ['sales-order.view'],
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.transport'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.transport}`,
            icon: 'fas fa-truck',
            permissions: ['logistic.order.view', 'logistic.ride.view'],
            hidden: this.isMobile,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.wms'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.warehouse}`,
            icon: 'fas fa-warehouse-alt',
            permissions: [
              'wms.warehouse.view',
              'wms.warehouse.manage',
              'wms.orderpicking.view',
              'wms.orderpicking.manage',
              'wms.dashboard.view',
              'wms.dashboard.manage',
            ],
            hidden: !isCashCarryCompany,
          },
          {
            label: this.translateService.stream('demooij.list.title.receive-release'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.goods}`,
            icon: 'fas fa-exchange',
            permissions: ['goods.receive.view', 'goods.receive.manage', 'goods.release.view', 'goods.release.manage'],
            hidden: this.isMobile || isGemotraCompany,
          },
          {
            label: this.translateService.stream('demooij.sidebar.menuitem.manage'),
            routerLink: `${AppRoutes.admin}/${AppRoutes.manage}`,
            icon: 'fas fa-cog',
            permissions: ['manage.view', 'view.driver'],
            hidden: this.isMobile,
          },
        ].filter((menu) => {
          if (!menu.permissions?.length) {
            return true;
          }

          return menu.permissions.some((permission) => {
            return this.ngxPermissionService.getPermission(permission);
          });
        });
      }),
    );
  }
}

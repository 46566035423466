<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <ng-container *ngIf="breadcrumbs?.length">
    <div *ngFor="let breadcrumb of breadcrumbs; let index = index; let last = last" class="breadcrumb-item-wrap">
      <a
        *ngIf="breadcrumb.title || breadcrumb.translationKey"
        class="link"
        [ngClass]="{ 'not-active': last }"
        (click)="onNavigateClick(breadcrumb)">
        {{ breadcrumb.translationKey ? (breadcrumb.translationKey | translate) : breadcrumb.title }}
      </a>

      <span *ngIf="!last && (breadcrumb.title || breadcrumb.translationKey)" class="arrow"
        ><i class="fas fa-chevron-right"></i
      ></span>
    </div>
  </ng-container>
</ng-container>

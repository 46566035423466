import { Component, HostListener, Injector, OnDestroy, ViewChild } from '@angular/core';
import { EntityAction } from '@core/enums/entity-action.enum';
import { BuildersFormKey, Entity } from '@core/enums/entity.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  CapturumFormRendererComponent,
  FormManipulatorService,
  FormRendererService,
  FormSaverService,
} from '@capturum/builders/form-renderer';
import { debounceTime, filter, finalize, first } from 'rxjs/operators';
import { DestroyBase } from '@capturum/shared';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { PurchaseOrderService } from '@features/purchase-order/services/purchase-order.service';

@Component({
  selector: 'app-entity-dialog-base',
  template: '',
})
export class EntityDialogBaseComponent extends DestroyBase implements OnDestroy {
  @ViewChild(CapturumFormRendererComponent)
  public formRenderer: CapturumFormRendererComponent;

  public submitting = false;
  public action: EntityAction;
  public entity: Entity | BuildersFormKey;
  public formKey: string;
  public entityFormKey: BuildersFormKey;
  public successfulMessage: string;
  public hideSuccessfulToast: boolean;
  public formManipulations: { [key: string]: string };
  public submitKeyEvents = ['Enter', 'NumpadEnter'];
  public submitting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public ref: DynamicDialogRef;
  public config: DynamicDialogConfig;
  protected formSaverService: FormSaverService;
  protected formRendererService: FormRendererService;
  protected toastNotificationService: ToastNotificationService;
  protected formManipulatorService: FormManipulatorService;
  protected store: Store;
  protected translateService: TranslateService;
  public purchaseOrderService: PurchaseOrderService;

  constructor(protected readonly injector: Injector) {
    super();

    this.ref = this.injector.get(DynamicDialogRef);
    this.formSaverService = this.injector.get(FormSaverService);
    this.formRendererService = this.injector.get(FormRendererService);
    this.config = this.injector.get(DynamicDialogConfig);
    this.toastNotificationService = this.injector.get(ToastNotificationService);
    this.formManipulatorService = this.injector.get(FormManipulatorService);
    this.store = this.injector.get(Store);
    this.translateService = this.injector.get(TranslateService);
    this.purchaseOrderService = this.injector.get(PurchaseOrderService);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    const formManipulations = this.config?.data?.formManipulations;

    if (formManipulations) {
      this.formManipulatorService.setFieldManipulation(this.formKey, formManipulations.field, {
        [formManipulations.manipulationKey]: formManipulations.manipulationValue,
      });
    }
  }

  @HostListener('window:keyup', ['$event'])
  public keyUpevent(event: KeyboardEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.submitKeyEvents.includes(event.code) && !this.submitting) {
      const closeDialog = [
        BuildersFormKey.formSalesOrderBulkPayment,
        BuildersFormKey.formPurchaseOrderProductComment,
        BuildersFormKey.formWmsSpotProduct,
        BuildersFormKey.formWmsSpotProductMove,
        BuildersFormKey.formWmsAssignOrder,
        BuildersFormKey.formWmsPickerLevels,
        BuildersFormKey.formWmsProductSplit,
        BuildersFormKey.formWmsProductReportChange,
        BuildersFormKey.formChangeSalesOrderProductQuantity,
      ].includes(this.entityFormKey);

      this.defaultSubmit(closeDialog);
    }
  }

  public defaultSubmit(close = false): void {
    if (this.formRenderer?.formConfiguration?.submit?.endpoint) {
      return;
    }

    if (
      this.config?.data?.key === BuildersFormKey.formPurchaseOrderProductTemplate &&
      this.config?.data?.data?.isAllocatedToRide
    ) {
      this.purchaseOrderService.openConfirmationDialog(() => {
        this.submitForm(close);
      });
    } else {
      this.submitForm(close);
    }
  }

  public submitForm(close: boolean): void {
    this.submitting = true;

    this.submitting$.next(true);

    this.formSaverService
      .submit(this.formKey)
      .pipe(
        debounceTime(100),
        finalize(() => {
          this.submitting = false;

          return this.submitting$.next(false);
        }),
        first(),
        filter(Boolean),
      )
      .subscribe({
        next: ({ response }) => {
          if (close) {
            this.ref.close(this.config?.data?.returnFormValue ? this.formRenderer.form.value : true);
          } else {
            if (this.config?.data?.updateTableData) {
              this.config?.data?.updateTableData();
            }

            this.formRenderer?.form?.reset();
            this.submitting = false;

            this.submitting$.next(false);
          }

          if (!response?.meta?.messages?.length) {
            this.showNotification();
          }

          if (
            response?.meta?.messages?.some((message) => {
              return message.type === 'warning';
            }) &&
            this.config?.data?.callbackAction
          ) {
            this.config?.data?.callbackAction();
          }
        },
        error: (error) => {
          if (this.config?.data?.errorHandler) {
            this.config?.data?.errorHandler(error);
          }
        },
      });
  }

  public showNotification(): void {
    if (this.hideSuccessfulToast) {
      return;
    }

    if (this.successfulMessage) {
      this.toastNotificationService.success(this.successfulMessage);

      return;
    }

    this.toastNotificationService.entityActionSuccess(this.entity, this.action);
  }
}

import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { Spot } from '@features/warehouse/modules/locations/models/spot.model';
import { Product } from '@core/models/product.model';
import { MapItem } from '@capturum/ui/api';
import { responseData } from '@capturum/builders/core';
import { ProductsForCheck } from '@features/warehouse/modules/locations/components/spot-move-products-dialog/spot-move-products-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WarehousesService {
  public refreshTotals$ = new BehaviorSubject<boolean>(true);

  constructor(private readonly apiHttp: ApiHttpService) {}

  public getSpotById(id: string): Observable<Spot> {
    return this.apiHttp.get(`/spot/${id}`);
  }

  public getSpotsList(): Observable<MapItem[]> {
    const url = `/spot/list`;

    return this.apiHttp.get(url).pipe(responseData);
  }

  public getListProductsForMove(data: [key: string]): Observable<{ data: Product[] }> {
    const url = `/spot-product/bulk-move-products`;

    return this.apiHttp.post(url, { id: data });
  }

  public moveProducts(data: ProductsForCheck): Observable<void> {
    const url = `/spot-product/move-products`;

    return this.apiHttp.post(url, { data });
  }

  public moveSpotProducts(): Observable<void> {
    const url = `/spot-product/move-products-no-location`;

    return this.apiHttp.post(url, {});
  }

  public getSpotsNumber(warehouseId: string): Observable<{ single_spot_id: string }> {
    const url = `/warehouse/${warehouseId}/single-spot-id`;

    return this.apiHttp.get(url);
  }
}

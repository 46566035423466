export class RouterUtil {
  public static backUrl(backUrl: string): object {
    return { queryParams: { returnUrl: backUrl } };
  }

  public static getReturnUrl(url: string): { url: string; queryParams: object } | undefined {
    const returnUrlKey = 'returnUrl=';

    if (!url.includes(returnUrlKey)) {
      return;
    }

    const urlPath = url?.split('?')[1];
    const queryParamsUrl = decodeURIComponent(urlPath?.split('&')[0] || '');
    const returnUrl = queryParamsUrl?.split(returnUrlKey)[1];
    const urlPathName = returnUrl?.split('?')[0];
    const params = queryParamsUrl?.split('?')[1];

    const queryParams = {};

    if (params) {
      // from: key1=value1&key2=value2 parameters
      // to: { key1: 'value1', key2: 'value2' }
      new URLSearchParams(params).forEach((value, key) => {
        return (queryParams[key] = value);
      });
    }

    return { url: urlPathName, queryParams: params ? queryParams : null };
  }
}

import { BuildersFormKey, Entity } from '@core/enums/entity.enum';
import { PanelBase } from '@core/classes/panel-base.class';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Product } from '@core/models/entity/product.model';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { FetchActionsExecution } from '@store/general/general.actions';
import { filter, Subject } from 'rxjs';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { EntityFormDialogComponent } from '@shared/modules/entity-dialog/components/entity-form-dialog/entity-form-dialog.component';
import { first, takeUntil } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-negative-stock-panel',
  templateUrl: './negative-stock-panel.component.html',
  styleUrls: ['./negative-stock-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NegativeStockPanelComponent extends PanelBase implements OnInit, OnDestroy {
  public item: Product;
  public Entity: typeof Entity = Entity;
  public purchaseOrderId: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private dialogService: DialogService,
    private actionService: CapturumBuilderActionService,
    protected translateService: TranslateService,
    private actions: Actions,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.fetchOpenPopupAction();
  }

  protected fetchOpenPopupAction(): void {
    this.actions
      .pipe(
        ofActionSuccessful(FetchActionsExecution),
        filter((item) => {
          return item.key === BuilderActionType.openPopup && item.item.options.action.key === 'change_quantity';
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        return this.openPopUp(data);
      });
  }

  private openPopUp(data: any): void {
    this.dialogService
      .open(EntityFormDialogComponent, {
        header: this.translateService.instant('demooij.list.title.change-quantity'),
        data: {
          entity: BuildersFormKey.formChangeSalesOrderProductQuantity,
          successfulMessage: this.translateService.instant(`toast.success.update`),
          key: BuildersFormKey.formChangeSalesOrderProductQuantity,
          hasSubmitAndContinue: false,
          modelId: data.item.item.id,
        },
        styleClass: 'full-height-mobile',
      })
      .onClose.pipe(first(), filter(Boolean))
      .subscribe(() => {
        this.actionService.broadcastActionExecuted(
          { key: 'refresh', type: 'refresh', options: { refresh: true } },
          null,
          null,
        );
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

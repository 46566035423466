import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { CustomColumn } from '@public/pricey/interfaces/custom-pricey-column.enum';
import { Customer } from '@store/public/public-state.model';
import { PublicSelectors } from '@store/public/public.selectors';
import { BehaviorSubject, filter, Observable, takeUntil } from 'rxjs';
import { CustomTableColumn } from '@public/pricey/interfaces/pricey-product-list.interface';
import {
  ActiveFilters,
  CapturumDynamicFiltersComponent,
  DynamicFilterConfig,
  DynamicFilterType,
} from '@capturum/ui/dynamic-filters';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { PublicPriceyService } from '@public/pricey/services/public-pricey.service';
import { ListOptions } from '@capturum/api';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { DestroyBase } from '@capturum/shared';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { Product } from '@core/models/entity/product.model';
import { CompanyCodesEnum } from '@core/enums/company-codes.enum';

@Component({
  selector: 'app-public-product-list-table',
  templateUrl: './public-product-list-table.component.html',
  styleUrls: ['./public-product-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PublicProductListTableComponent extends DestroyBase implements OnInit {
  @Select(PublicSelectors.getCustomer) public customer$: Observable<Customer>;

  @Input()
  public set tableData(value: Product[]) {
    if (value && Array.isArray(value)) {
      if (!value.length) {
        this.form = new UntypedFormGroup({});
        this._tableData = value;
        this.isTableReady = true;
        this.cdr.detectChanges();
      } else {
        this.addControlsToForm(value.slice(this.tableData.length, value.length));
      }
    }
  }

  @Input() public columns: CustomTableColumn[];
  @Input() public editableTable: boolean;
  @Input() public showFilters = false;
  @Input() public isCashCarryCompany = false;
  @Input() public loadInProgress: boolean;

  @Output() public tableDataChange = new EventEmitter<Product>();
  @Output() public filterChange = new EventEmitter<ListOptions>();

  @ViewChild(CapturumDynamicFiltersComponent)
  public dynamicFiltersRef: CapturumDynamicFiltersComponent;

  public get tableData(): Product[] {
    return this._tableData;
  }

  public showStickyTopHeader = new BehaviorSubject(false);
  public isMobile: boolean;
  public form = new UntypedFormGroup({});
  public CustomColumn = CustomColumn;
  public dynamicFilters: DynamicFilterConfig;
  public isTableReady = false;
  public activeFilters: ActiveFilters[] = [];
  public mainGroupOptions$: Observable<MapItem[]>;
  public selectedMainGroups: string[] = [];
  public loadFullList = true;
  public isGemotraCompany: boolean;

  private _tableData: Product[] = [];

  @HostListener('window:scroll', ['$event'])
  public onWindowScroll() {
    if (this.isMobile || !this.tableData.length) {
      return;
    }

    // Hide sticky table header if on page are visible any other headers
    const tableHeaderIsVisible = Array.from(document.getElementsByClassName('table-header')).some((header) => {
      const position = header.getBoundingClientRect();
      // Main top header + sticky 'Products' header height
      const visibleHeaderStaticHeight = 140;

      return position.top >= visibleHeaderStaticHeight && position.bottom <= window.innerHeight;
    });

    this.showStickyTopHeader.next(!tableHeaderIsVisible);
  }

  constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly publicPriceyService: PublicPriceyService,
    protected breakPointService: BreakPointService,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);

    this.isGemotraCompany =
      this.store.selectSnapshot(PublicSelectors.getCustomer)?.companyCode === CompanyCodesEnum.gemotra;

    this.mainGroupOptions$ = this.publicPriceyService.getMainGroupOptions();

    this.breakPointService.screenSize$
      .pipe(
        filter((size) => {
          return this.isMobile !== (size !== ScreenSize.desktop);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((size) => {
        this.isMobile = size !== ScreenSize.desktop;

        this.cdr.detectChanges();
      });

    this.dynamicFilters = {
      filters: [
        {
          type: DynamicFilterType.input,
          field: 'search',
          icon: 'fas fa-search',
          matchMode: FilterMatchMode.LIKE,
          label: null,
          placeholder: this.translateService.instant('button.search'),
        },
        {
          type: DynamicFilterType.multiselect,
          field: 'productGroup.mainGroup.id',
          icon: 'fas fa-boxes',
          matchMode: FilterMatchMode.IN,
          operator: FilterMatchMode.IN,
          label: null,
          styleClass: 'maingroup-multiselect-filter',
          resetFilterOnHide: true,
          placeholder: this.translateService.instant('demooij.entity-name.main-group.plural'),
          options: this.mainGroupOptions$,
        },
      ],
    };

    this.cdr.detectChanges();
  }

  public resetFilters(): void {
    this.loadFullList = true;
    this.activeFilters = [];

    this.handleLoadAllSwitchChange(true);
  }

  public updateFormValues(increment: boolean, formArrayName?: string, formControlName?: string): void {
    const currentValue = this.form.get(formArrayName).get(formControlName).value;
    const newValue = increment ? currentValue + 1 : currentValue - 1;

    if (newValue >= 0) {
      this.form.get(formArrayName).get(formControlName).setValue(newValue);

      if (this.isCashCarryCompany) {
        this.onBlur(formArrayName, formControlName);
      } else {
        const product = this.form.get(formArrayName).get('product').value;

        product[formControlName] = this.form.get(formArrayName).get(formControlName).value;

        this.tableDataChange.emit(product);
      }
    }
  }

  public addControlsToForm(products: Product[]): void {
    const savedProductList = this.store.selectSnapshot(PublicSelectors.getEditedProductsList);
    const storedEditedProducts: Product[] = savedProductList?.length ? [...savedProductList] : [];

    products.forEach((product) => {
      if (!product?.id) {
        return;
      }

      const storedProduct = storedEditedProducts?.find((item) => {
        return item.id === product.id;
      });
      const rowFormGroup = this.formBuilder.group({});

      this.columns
        .filter((column) => {
          return column.isInput;
        })
        .forEach((column) => {
          const initialValue = storedProduct?.[column?.template];

          if (
            (this.isCashCarryCompany && column.template === CustomColumn.unit) ||
            column.template !== CustomColumn.unit
          ) {
            rowFormGroup.setControl(
              column.template,
              this.formBuilder.control({
                value: initialValue || null,
                disabled: column.template === CustomColumn.unit && product.amount_per_package === 1,
              }),
            );
          }
        });

      rowFormGroup.addControl('product', new UntypedFormControl(storedProduct || product));

      this.form.addControl(product.id, rowFormGroup);
      this._tableData.push(product);
    });

    this._tableData = [...this._tableData];
    this.isTableReady = true;
    this.cdr.detectChanges();
  }

  public onBlur(formArrayName: string, formControlName: string): void {
    let product = { ...this.form.get(formArrayName).get('product').value };
    const changedValue = this.form.get(formArrayName).get(formControlName).value;

    if (product[formControlName] === changedValue) {
      return;
    }

    product = {
      ...product,
      [formControlName]: this.form.get(formArrayName).get(formControlName).value,
    };

    this.form.get(formArrayName).get('product').setValue(product);

    this.tableDataChange.emit(product as Product);
  }

  public handleFilterChange(activeFilters: ActiveFilters[]): void {
    const search = activeFilters?.find((filter) => {
      return filter.field === 'search';
    })?.value;

    const filters = activeFilters
      .filter((filter) => {
        return filter.field !== 'search';
      })
      .map((item) => {
        return { ...item, operator: item.matchMode };
      });

    this.activeFilters = activeFilters;

    this.filterChange.emit({ search, filters });
  }

  public handleMainGroupChange(): void {
    if (this.loadFullList) {
      this.loadFullList = false;
    }

    const filters = this.activeFilters.filter((filter) => {
      return filter.field !== 'productGroup.mainGroup.id';
    });

    if (this.selectedMainGroups.length) {
      this.handleFilterChange([
        ...filters,
        {
          field: 'productGroup.mainGroup.id',
          value: this.selectedMainGroups,
          matchMode: FilterMatchMode.IN,
        },
      ]);
    } else {
      this.loadFullList = true;
      this.handleLoadAllSwitchChange(true);
    }
  }

  public handleLoadAllSwitchChange(checked: boolean): void {
    const filters = this.activeFilters.filter((filter) => {
      return filter.field !== 'productGroup.mainGroup.id';
    });

    if (checked) {
      this.selectedMainGroups = [];
      this.handleFilterChange(filters);
    } else {
      this.handleFilterChange([
        ...filters,
        {
          field: 'productGroup.mainGroup.id',
          value: ['EMPTY'],
          matchMode: FilterMatchMode.IN,
        },
      ]);
    }
  }
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Customer } from '@store/public/public-state.model';
import { PublicSelectors } from '@store/public/public.selectors';
import { DialogService } from 'primeng/dynamicdialog';
import { concatMap, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PublicPriceyService } from '@public/pricey/services/public-pricey.service';
import { CustomTableColumn } from '@public/pricey/interfaces/pricey-product-list.interface';
import { ActionButton } from '@core/models/action-button.model';
import { ApiIndexResult, ListOptions } from '@capturum/api';
import { BreakPointService } from '@shared/services/breakpoint.service';
import { ScreenSize } from '@core/enums/screen-size.enum';
import { DestroyBase } from '@capturum/shared';
import { Product } from '@core/models/entity/product.model';
import { SetProducts } from '@store/public/public.actions';

@Component({
  selector: 'app-entity-custom-list-base',
  template: '',
})
export class EntityCustomListBaseComponent extends DestroyBase implements OnInit, OnDestroy {
  @Select(PublicSelectors.getCustomer) public customer$: Observable<Customer>;

  public actionButtons: ActionButton[];
  public tableData: Product[] = [];
  public columns: CustomTableColumn[];
  public isMobile: boolean;
  public tableDataLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public updateTableData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isBottomReached = false;
  public isTableReady = false;
  public isLoadingNextPages = false;
  public oldListApplied = false;

  private listOptions: ListOptions = {
    page: 1,
    perPage: 20,
  };

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly store: Store,
    protected readonly publicPriceyService: PublicPriceyService,
    protected readonly dialogService: DialogService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly breakPointService: BreakPointService,
  ) {
    super();

    const code = this.store.selectSnapshot(PublicSelectors.getCustomer)?.locale?.code;

    this.translateService.use(code ?? this.translateService.defaultLang);

    this.updateTableData$
      .asObservable()
      .pipe(
        filter(Boolean),
        concatMap(() => {
          return timer(this.isLoadingNextPages ? 1000 : 0);
        }),
        switchMap(() => {
          return this.publicPriceyService.getPublicProductList(this.listOptions).pipe(
            map((result) => {
              return {
                result,
                isFilterRequest: !!this.listOptions.search || !!this.listOptions.filters?.length,
              };
            }),
          );
        }),
        filter(() => {
          return !this.oldListApplied;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ({ result, isFilterRequest }: { result: ApiIndexResult<Product>; isFilterRequest: boolean }) => {
          this.setTableData(result.data);

          this.isBottomReached = this.tableData?.length === result?.meta?.pagination?.total;

          if (!this.isBottomReached) {
            this.listOptions.page = this.listOptions.page + 1;
            this.isLoadingNextPages = true;
            this.updateTableData$.next(true);
          } else {
            // if no filters are applied and bottom is reached (So, if the full list is loaded, save it in store)
            if (!isFilterRequest) {
              this.store.dispatch(new SetProducts(this.tableData));
              this.updateTableData$.next(false);
            }
          }
        },
        error: () => {
          this.tableDataLoading$.next(false);
        },
      });
  }

  public ngOnInit(): void {
    this.breakPointService.screenSize$
      .pipe(
        filter((size) => {
          return this.isMobile !== (size !== ScreenSize.desktop);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((size) => {
        this.isMobile = size !== ScreenSize.desktop;

        this.handleScreenSizeChange();
      });

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.store.dispatch(new SetProducts([]));
  }

  public onFiltersChange({ filters, search }: ListOptions): void {
    this.tableData = [];
    this.tableDataLoading$.next(true);
    this.changeDetectorRef.detectChanges();

    this.listOptions = {
      ...this.listOptions,
      filters,
      search,
      page: 1,
    };

    const savedProductList = this.store.selectSnapshot(PublicSelectors.getFullProductList);

    // if the table is reset, apply the saved list of products
    if (!search && !filters?.length && Array.isArray(savedProductList) && savedProductList?.length) {
      this.tableData = savedProductList;
      this.oldListApplied = true;
      this.isLoadingNextPages = false;
      this.tableDataLoading$.next(false);
      this.isBottomReached = true;
      this.changeDetectorRef.detectChanges();

      return;
    }

    this.oldListApplied = false;
    this.isLoadingNextPages = false;
    this.updateTableData$.next(true);
  }

  // This method serves as a hook
  protected handleScreenSizeChange(): void {}

  private setTableData(productList: Product[]): void {
    this.tableData = this.tableData.concat(productList);
    this.tableDataLoading$.next(false);

    if (!this.tableData.length) {
      this.isBottomReached = true;
    }

    this.changeDetectorRef.detectChanges();
  }
}

import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

/**
 * This directive prevent double click
 *
 * @example
 *
 * <button appPreventDoubleClick (clickEvent)="clickTrigger()" [debounceTime]="1000">Prevent Double Click till 1s</button>
 *
 */

@Directive({
  selector: '[appPreventDoubleClick]',
})
export class DoubleClickDirective extends DestroyBase implements OnInit {
  @Input() public debounceTime: number;
  @Input() public submitting: boolean;
  @Output() public clickEvent: EventEmitter<Event> = new EventEmitter();
  private clicks: Subject<Event> = new Subject();

  public ngOnInit(): void {
    const time = typeof this.debounceTime === 'number' ? this.debounceTime : 500;

    this.clicks.pipe(debounceTime(time), takeUntil(this.destroy$)).subscribe((event: Event) => {
      this.clickEvent.emit(event);
    });
  }

  @HostListener('click', ['$event'])
  public clickEventListener(event: Event): void {
    event?.preventDefault();
    event?.stopPropagation();

    if (!this.submitting) {
      this.clicks.next(event);
    }
  }
}

<div class="public-layout">
  <div class="public-layout__page">
    <div class="public-layout__header">
      <app-header [logoUrl]="'/assets/images/' + image"></app-header>
    </div>

    <div class="public-layout__content">
      <div class="message">
        {{ 'demooij.public.pricey.expired-link' | translate }}
      </div>
    </div>
  </div>
</div>

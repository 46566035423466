import { Component } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { EntityFormDialogComponent } from '@shared/modules/entity-dialog/components/entity-form-dialog/entity-form-dialog.component';
import { first } from 'rxjs/operators';
import { filter } from 'rxjs';
import { DeMooijDialogService } from '@core/services/de-mooij-dialog-service.service';
import { TranslateService } from '@ngx-translate/core';
import { WarehousesService } from '@features/warehouse/modules/locations/services/warehouses.service';

@Component({
  selector: 'app-list-renderer-widget-move-action',
  templateUrl: './list-renderer-widget-move-action.component.html',
  styleUrls: ['./list-renderer-widget-move-action.component.scss'],
})
export class ListRendererWidgetMoveActionComponent extends ListRendererWidgetBase {
  constructor(
    protected dialogService: DeMooijDialogService,
    protected translateService: TranslateService,
    protected warehouseSevice: WarehousesService,
  ) {
    super();
  }

  public openPopUp(): void {
    this.dialogService
      .open(EntityFormDialogComponent, {
        header: this.translateService.instant('demooij.list.action.move'),
        data: {
          entity: 'form_wms-spot-product-move',
          key: 'form_wms-spot-product-move',
          successfulMessage: this.translateService.instant('demooij.toast.success.product-move-to-another-spot'),
          hasSubmitAndContinue: false,
          modelId: this.item.id,
        },
        styleClass: 'full-height-mobile row-move-product',
      })
      .onClose.pipe(first(), filter(Boolean))
      .subscribe(() => {
        this.warehouseSevice.refreshTotals$.next(true);
      });
  }
}

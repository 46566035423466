import { CapturumActionMenuModule } from '@capturum/ui/action-menu';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { PageLayoutHeaderComponent } from '@shared/components/page-layout-header/page-layout-header.component';
import { GetActionPipe } from '@shared/pipes/get-action.pipe';
import { GetBackPipe } from '@shared/pipes/get-back.pipe';
import { DetailsComponent } from '@shared/components/details/details.component';
import { DetailsValuesComponent } from '@shared/components/details-values/details-values.component';
import { TooltipModule } from 'primeng/tooltip';
import { PanelContentWrapperComponent } from './components/panel-content-wrapper/panel-content-wrapper.component';
import { TabLayoutHeaderComponent } from './components/tab-layout-header/tab-layout-header.component';
import { GetKeyPipe } from './pipes/get-key.pipe';
import { CreditnoteBaseComponent } from './components/creditnote-base/creditnote-base.component';
import { ProductAllocationDetailsPanelComponent } from './components/product-allocation-details-panel/product-allocation-details-panel.component';
import { GetTranslationPipe } from './pipes/get-translation.pipe';
import { DoubleClickDirective } from './directives/double-click.directive';
import { BoolifyPipe } from './pipes/boolify.pipe';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SumPipe } from './pipes/sum.pipe';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { SearchInputFilterComponent } from './components/search-input-filter/search-input-filter.component';
import { CapturumDynamicFiltersModule } from '@capturum/ui/dynamic-filters';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableHeaderInfoComponent } from './components/table-header-info/table-header-info.component';
import { CheckNullUndefinedPipe } from './pipes/check-null-undefined.pipe';
import { IconPipe } from './pipes/icon.pipe';
import { SplitProductDescription } from './pipes/split-product-description.pipe';
import { SplitAtCommaPipe } from './pipes/split-at-comma.pipe';

const BASE_COMPONENTS = [
  PageLayoutHeaderComponent,
  DetailsComponent,
  DetailsValuesComponent,
  PanelContentWrapperComponent,
  TabLayoutHeaderComponent,
];

const BASE_PIPES = [
  GetActionPipe,
  GetBackPipe,
  GetKeyPipe,
  GetTranslationPipe,
  BoolifyPipe,
  SumPipe,
  CheckNullUndefinedPipe,
  IconPipe,
  SplitProductDescription,
];

const BASE_DIRECTIVES = [DoubleClickDirective];

@NgModule({
  declarations: [
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES,
    GetKeyPipe,
    CreditnoteBaseComponent,
    GetTranslationPipe,
    ProductAllocationDetailsPanelComponent,
    SearchInputFilterComponent,
    TableHeaderInfoComponent,
    SplitAtCommaPipe,
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CapturumSharedModule,
    TooltipModule,
    CapturumActionMenuModule,
    CapturumDynamicFiltersModule,
    CapturumInputModule,
    CapturumListRendererModule,
    SplitButtonModule,
  ],
  exports: [
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    ProductAllocationDetailsPanelComponent,
    TableHeaderInfoComponent,
    ...BASE_COMPONENTS,
    ...BASE_PIPES,
    ...BASE_DIRECTIVES,
    SplitAtCommaPipe,
  ],
})
export class SharedModule {}

import { map } from 'rxjs/operators';
import { Customer } from '@store/public/public-state.model';
import { Injectable } from '@angular/core';
import { ApiService, ListOptions, ApiHttpService, ApiSingleResult } from '@capturum/api';
import { Observable, Subject } from 'rxjs';

interface Company {
  logo: string;
  domain: string;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class PriceyService extends ApiService<any> {
  public showLoader: Subject<boolean> = new Subject<boolean>();
  protected endpoint = 'pricey';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getCustomer(options?: ListOptions): Observable<Customer> {
    let url = `/${this.endpoint}/customer`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<Customer>>(url)?.pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }

  public getCompany(options?: ListOptions): Observable<Company> {
    let url = `/${this.endpoint}/company`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<ApiSingleResult<Company>>(url)?.pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }
}

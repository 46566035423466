import { Component, OnInit } from '@angular/core';
import { PriceyService } from '@core/api/pricey-api.service';

@Component({
  selector: 'app-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.scss'],
})
export class ExpiredLinkComponent implements OnInit {
  public image = 'logo.png';

  constructor(private priceyService: PriceyService) {}

  public ngOnInit(): void {
    this.priceyService.showLoader.next(false);

    if (window.location.host.includes('hermes-exports')) {
      this.image = 'logo-hermes-exports.svg';
    }
  }
}

import { Component, Injector, OnInit } from '@angular/core';
import { EntityDialogBaseComponent } from '../../../entity-base/components/entity-dialog-base/entity-dialog-base.component';

@Component({
  selector: 'app-entity-form-dialog',
  templateUrl: './entity-form-dialog.component.html',
})
export class EntityFormDialogComponent extends EntityDialogBaseComponent implements OnInit {
  public hasSubmitAndContinue: boolean;
  public hideSuccessfulToast: boolean;

  constructor(protected injector: Injector) {
    super(injector);
  }

  public ngOnInit(): void {
    this.hasSubmitAndContinue = this.config?.data?.hasSubmitAndContinue ?? true;
    this.formKey = this.config?.data?.key;
    this.entity = this.config?.data?.entity;
    this.entityFormKey = this.config?.data?.key;
    this.action = this.config?.data?.action;
    this.successfulMessage = this.config?.data?.successfulMessage;
    this.hideSuccessfulToast = this.config?.data?.hideSuccessfulToast;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@core/enums/entity.enum';

@Pipe({
  name: 'getKey',
})
export class GetKeyPipe implements PipeTransform {
  public transform(type: 'form' | 'list', entity: Entity, childEntity?: Entity): string {
    if (entity && type) {
      if (childEntity) {
        return `${type}_${entity}-${childEntity}`;
      }

      return `${type}_${entity}`;
    }

    return null;
  }
}

import { Component } from '@angular/core';
import { OrderDetailPanel } from '@core/models/order-datails-panel.model';
import { PanelBase } from '@core/classes/panel-base.class';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
})
export class DetailsComponent extends PanelBase {
  public data: OrderDetailPanel[];
}

import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

import { DateUtil } from '@core/utils/date-util';
import { DatesFormats } from '@core/configs/date-config';
import { FilterMatchMode } from '@capturum/ui/api';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';

@Component({
  selector: 'app-list-renderer-widget-text-style-by-condition',
  styleUrls: ['./list-renderer-widget-text-style-by-condition.component.scss'],
  template: `
    {{ prefix ?? '' }}
    <div
      data-test="text-style-by-condition"
      [class]="item.condition ? css_class : ''"
      [innerHTML]="item[viewField] ?? item[field]"
      [capCellTooltip]="item[viewField] ?? item[field]"></div>
  `,
  providers: [CurrencyPipe],
})
export class ListRendererWidgetTextStyleByConditionComponent extends ListRendererWidgetBase implements OnInit {
  public type: string;
  // tslint:disable-next-line:variable-name
  public css_class: string;
  // tslint:disable-next-line:variable-name
  public condition_field: string;
  public operation: string;
  public value: string;
  public viewField: string;
  public prefix: string;
  public locale = 'nl';
  public display: 'symbol' | 'code' = 'symbol';
  public digitsInfo: string;

  constructor(private currencyPipe: CurrencyPipe) {
    super();
  }

  public ngOnInit(): void {
    let condition: boolean;

    switch (this.type) {
      case 'date': {
        const conditionKey = this.condition_field ?? this.field;

        condition = this.checkDateCondition(conditionKey);

        if (conditionKey === this.field) {
          this.viewField = `view-${this.field}`;

          this.item[this.viewField] = new DatePipe('en-US').transform(
            this.item[this.field],
            DatesFormats.displayFormat,
          );
        }
        break;
      }
      case 'boolean': {
        if (this.item?.show_bold) {
          condition = true;
        }

        if (this.field === 'purchaseOrder.unloading_date') {
          this.viewField = `view-${this.item.purchaseOrder.unloading_date}`;

          this.item[this.viewField] = new DatePipe('en-US').transform(
            this.item['purchaseOrder.unloading_date'],
            DatesFormats.displayFormat,
          );
        }

        break;
      }
      case 'color': {
        if (this.item?.[this.condition_field]) {
          this.css_class = `${this.css_class} ${this.item[this.condition_field].toLowerCase() || ''}`;
          condition = true;
        }
        break;
      }
      // other type case

      default:
        condition = false;
        break;
    }

    // If price is receive in this format: "0.09 (Vast)", separate price and text
    const [price, extraPriceInfo]: string[] = this.item.cost_price ? this.item.cost_price.split(' ') : [0, ''];
    const tempCostPrice = this.currencyPipe.transform(
      parseFloat(price),
      ' ',
      this.display,
      this.digitsInfo,
      this.locale,
    );

    this.item = {
      ...this.item,
      cost_price: extraPriceInfo ? `${tempCostPrice} ${extraPriceInfo}` : tempCostPrice,
      condition,
    };
  }

  private checkDateCondition(conditionKey: string): boolean {
    switch (this.operation) {
      case FilterMatchMode.GREATER_OR_EQUAL:
        return DateUtil.isBiggerOrEqual(this.item[conditionKey], this.value);

      case FilterMatchMode.GREATER:
        return DateUtil.isBigger(this.item[conditionKey], this.value);

      case FilterMatchMode.EQUALS:
        return DateUtil.isEqual(this.item[conditionKey], this.value);
      default:
        break;
    }
  }
}

import { ChangeDetectorRef, Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import { EntityListBaseComponent } from '@shared/modules/entity-base/components/entity-list-base/entity-list-base.component';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { Entity } from '@core/enums/entity.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ApiHttpService, ApiSingleResult } from '@capturum/api';
import { ToastNotificationService } from '@shared/services/toast-notification.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-creditnote-base',
  template: '',
})
export class CreditnoteBaseComponent extends EntityListBaseComponent implements OnDestroy {
  @ViewChild(CapturumListRendererComponent)
  public listRenderer: CapturumListRendererComponent;

  public entity: Entity;
  public parentEntity: Entity;
  public readonly ref: DynamicDialogRef;
  public readonly config: DynamicDialogConfig;
  protected readonly toastNotificationService: ToastNotificationService;
  protected readonly apiHttp: ApiHttpService;

  constructor(
    protected readonly router: Router,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr, injector);
    this.ref = injector?.get(DynamicDialogRef);
    this.config = injector?.get(DynamicDialogConfig);
    this.apiHttp = injector?.get(ApiHttpService);
    this.toastNotificationService = injector?.get(ToastNotificationService);
  }

  protected get orderNumber$(): Observable<string> {
    return this.apiHttp
      .get<ApiSingleResult<{ order_number: string }>>(`/${this.parentEntity}/${this.config.data?.identifier}`)
      .pipe(
        map((response) => {
          return response.data.order_number;
        }),
      );
  }
}

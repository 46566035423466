enum EntityRoutes {
  public = 'public',
  admin = 'admin',
  user = 'users',
  role = 'roles',
  translation = 'translations',
  dieselSurcharge = 'diesel-surcharge',
  baseData = 'base-data',
  setting = 'settings',
  tenant = 'tenants',
  dashboard = 'dashboard',
  product = 'product',
  address = 'address',
  mainGroup = 'main-group',
  characteristic = 'characteristic',
  productGroup = 'product-group',
  supplier = 'supplier',
  customer = 'customer',
  warehouse = 'warehouse',
  warehouses = 'warehouses',
  contact = 'contact',
  costType = 'cost-type',
  purchaseOrder = 'purchase-order',
  status = 'status',
  template = 'template',
  dayPrice = 'day-price',
  country = 'country',
  language = 'language',
  currency = 'currency',
  values = 'values',
  supplierPrice = 'supplier-price',
  salesOrder = 'sales-order',
  priceGroup = 'price-group',
  stock = 'stock',
  stockCount = 'stock-count',
  stockInLineEdit = 'stock-in-line-edit',
  negativeStock = 'negative-stock',
  pricey = 'pricey',
  orderProduct = 'order-product',
  placeOrder = 'place-order',
  report = 'report',
  expired = 'expired',
  exchangeRate = 'exchange-rate',
  transport = 'transport',
  driver = 'driver',
  goods = 'goods',
  fitonQuestion = 'fiton-question',
  creditNote = 'credit-note',
  orderpicking = 'orderpicking',
  transfer = 'transfer',
  completedPickOrderItems = 'completed-pick-order-items',
  location = 'location',
  palletReturn = 'pallet-return',
  purchaseOrderCost = 'purchase-order-cost',
  salesOrderCost = 'sales-order-cost',
  supplierCost = 'supplier-cost',
  price = 'price',
  stockMutation = 'stock-mutation',
  percentage = 'percentage',
  payment = 'payment',
  submitStock = 'submit_stock',
  forecast = 'forecast',
  transportType = 'transport-type',
  spot = 'spot',
  userActivity = 'user-activity',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
  manage = 'manage',
  builder = 'builder',
  configuration = 'configuration',
  release = 'release',
  menu = 'menu',
}

enum GeneralRoutes {
  detail = 'detail',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
};

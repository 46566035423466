import { Selector } from '@ngxs/store';
import { PanelState } from '@store/panel/panel.state';
import { PanelStateModel } from '@store/panel/panel-state.model';
import { PanelConfig } from '@core/models/panel-config.model';

export class PanelSelectors {
  @Selector([PanelState])
  public static getConfig(state: PanelStateModel): PanelConfig {
    return state.config;
  }
}

import { Selector } from '@ngxs/store';
import { PublicStateModel, Customer } from './public-state.model';
import { PublicState } from './public.state';
import { Product } from '@core/models/entity/product.model';

export class PublicSelectors {
  @Selector([PublicState])
  public static getFullProductList(state: PublicStateModel): Product[] {
    return state?.products;
  }

  @Selector([PublicState])
  public static getEditedProductsList(state: PublicStateModel): Product[] {
    return state?.editedProducts;
  }

  @Selector([PublicState])
  public static getCustomer(state: PublicStateModel): Customer {
    return state?.customer;
  }
}

<div class="details">
  <div class="details__header">
    <span>{{ title }}</span>

    <div class="icons" *ngIf="actions?.length">
      <i
        *ngFor="let action of actions"
        [class]="action.icon"
        tooltipPosition="top"
        [pTooltip]="action?.tooltip"
        (click)="executeAction(action)">
      </i>
    </div>
  </div>

  <div class="details__content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>

import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Fresh Vision',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://de-mooij-test.staging.emendis.nl/api',
  sentryUrl: 'https://e4ca9c287db44263aafc261e29d63a9b@o4504360297496576.ingest.sentry.io/4504377860554752',
  databaseName: 'emendis.complete',
  reportIdStock: '9ae0d79b-4641-48d6-b12c-6926d24b126f',
  reportIdForecast: '39cde4b2-f05d-4717-9ed1-9b8da32f9dc4',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://de-mooij.test.emendis.nl'
};

<div [class]="'panel-content ' + 'row-' + contents?.length">
  <ng-container *ngFor="let content of contents">
    <div class="title">
      {{ content.title }}
    </div>

    <div class="list-wrapper">
      <cpb-list-renderer
        *ngIf="content?.context; else loadingSkeleton"
        [ngClass]="content?.key"
        [key]="content?.key"
        [context]="content.context">
      </cpb-list-renderer>
    </div>
  </ng-container>
</div>

<ng-template #loadingSkeleton>
  <cap-info-table-skeleton></cap-info-table-skeleton>
</ng-template>

<ng-container *ngIf="showFilters">
  <ng-container *ngIf="mainGroupOptions$ | async as mainGroups">
    <div class="row">
      <div class="col-lg-10 filters-wrap">
        <span class="label">Filter</span>
        <cap-dynamic-filters
          #dynamicFiltersComponent
          class="public-table-filters"
          [ngClass]="{ 'disabled-overlay': tableDataLoading$ | async }"
          [showResetButton]="false"
          [filterConfig]="dynamicFilters"
          [(activeFilters)]="activeFilters"
          (activeFiltersChange)="handleFilterChange($event)">
        </cap-dynamic-filters>

        <cap-button styleClass="cap-button__gray" [label]="'demooij.btn.reset' | translate" (onClick)="resetFilters()">
        </cap-button>
      </div>

      <div class="d-flex flex-column justify-content-center align-items-end col-lg-2">
        <div class="switch-container" [ngClass]="{ 'd-none': isGemotraCompany }">
          <cap-input-switch [(ngModel)]="loadFullList" (change)="handleLoadAllSwitchChange($event.checked)">
          </cap-input-switch>

          <label>{{ 'demooij.public.pricey.load-full-list' | translate }}</label>
        </div>
      </div>
    </div>

    <div class="main-groups flex-wrap">
      <div class="checkbox-wrapper" *ngFor="let mainGroup of mainGroups">
        <p-checkbox
          #cbox
          [(ngModel)]="selectedMainGroups"
          name="mainGroups"
          [label]="mainGroup.label"
          [value]="mainGroup.key"
          (onChange)="handleMainGroupChange()">
        </p-checkbox>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="isTableReady && !loadInProgress; else loaderRef">
  <p-table
    #table
    dataKey="id"
    id="pricey"
    rowGroupMode="subheader"
    groupRowsBy="order"
    styleClass="cap-info-table"
    [value]="tableData"
    [formGroup]="form"
    [ngClass]="{ cashCarry: isCashCarryCompany }">
    <ng-template *ngIf="!isGemotraCompany" pTemplate="header">
      <tr *ngIf="showStickyTopHeader | async" class="table-header sticky">
        <th *ngFor="let column of columns" [class]="column.field || ''">
          {{ column.header | translate }}
        </th>
      </tr>

      <tr class="main-invisible-header">
        <th *ngFor="let column of columns" [class]="column.field || ''"></th>
      </tr>

      <tr class="main-invisible-header">
        <th *ngFor="let column of columns" [class]="column.field || ''"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
      <tr *ngIf="!isMobile && (rowData.id | formGroup : form) as rowFormGroup" [formGroup]="rowFormGroup">
        <td *ngFor="let column of columns" [class]="column.field">
          <ng-container [ngSwitch]="column.template">
            <ng-container *ngSwitchCase="CustomColumn.unit">
              <cap-input-number
                mode="decimal"
                [formControl]="column.template | formGroup : rowFormGroup"
                [selectOnFocus]="true"
                (blur)="onBlur(rowData.id, column.template)">
              </cap-input-number>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.quantity">
              <cap-input-number
                mode="decimal"
                locale="de-DE"
                [formControl]="column.template | formGroup : rowFormGroup"
                [selectOnFocus]="true"
                (blur)="onBlur(rowData.id, column.template)">
              </cap-input-number>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.remarks">
              <cap-input
                [formControl]="column.template | formGroup : rowFormGroup"
                [selectOnFocus]="true"
                (blur)="onBlur(rowData.id, column.template)">
              </cap-input>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.netPrice">
              <span>{{ +rowData[column.field] | number : '1.2-2' : 'nl' }}</span>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.customerPrice">
              <div class="d-flex">
                <span
                  [ngClass]="{
                    'd-none': column.field === CustomColumn.customerUnitPrice && rowData?.amount_per_package === 1
                  }">
                  {{ (customer$ | async)?.currency?.prefix }}{{ +rowData[column.field] | number : '1.2-2' : 'nl' }}
                </span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.productName">
              <div class="product-name">
                <div
                  class="desktop product-name-tooltip"
                  [capCellTooltip]="rowData[column.field] | observablePipe | async">
                  {{ rowData[column.field] }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="CustomColumn.productDetails">
              <ng-container *ngIf="rowData.name as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.details as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.size as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.brand as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.origin as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.packages_per_pallet as value">{{ value }},</ng-container>
              <ng-container *ngIf="rowData.packaging as value">{{ value }}</ng-container>
            </ng-container>

            <ng-container *ngSwitchDefault>
              {{ rowData[column.field] }}
            </ng-container>
          </ng-container>
        </td>
      </tr>

      <tr *ngIf="isMobile" class="mobile-row">
        <div
          class="clickable-row"
          [ngClass]="{ 'disable-click': isGemotraCompany }"
          (click)="expandedRow.hidden = !expandedRow.hidden">
          <td *ngFor="let column of columns" [class]="column.field">
            <ng-container [ngSwitch]="column.template">
              <ng-container *ngSwitchCase="CustomColumn.productDetails">
                <ng-container *ngIf="rowData.name as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.details as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.size as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.brand as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.origin as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.packages_per_pallet as value">{{ value }},</ng-container>
                <ng-container *ngIf="rowData.packaging as value">{{ value }}</ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="CustomColumn.customerPrice">
                <ng-container *ngIf="isCashCarryCompany && rowData?.amount_per_package > 1; else customerPriceRef">
                  <div class="d-flex flex-column cash-and-carry-price">
                    <span class="quantity"
                      >{{ (customer$ | async)?.currency?.prefix
                      }}{{ +rowData[column.field] | number : '1.2-2' : 'nl' }}</span
                    >
                    <span class="unit"
                      >{{ (customer$ | async)?.currency?.prefix
                      }}{{ +rowData.customer_unit_price | number : '1.2-2' : 'nl' }}</span
                    >
                  </div>
                </ng-container>

                <ng-template #customerPriceRef>
                  <div class="d-flex">
                    <span
                      >{{ (customer$ | async)?.currency?.prefix
                      }}{{ +rowData[column.field] | number : '1.2-2' : 'nl' }}</span
                    >
                  </div>
                </ng-template>
              </ng-container>

              <ng-container *ngSwitchCase="CustomColumn.productName">
                <div class="product-name">
                  <div class="mobile">
                    <span class="name">{{ rowData.name }}</span>
                    <ng-container *ngIf="!isGemotraCompany">
                      <div class="details">
                        <span *ngIf="rowData.origin" class="detail">{{ rowData.origin }} | &nbsp;</span>
                        <span *ngIf="rowData.brand" class="detail">{{ rowData.brand }} | </span>
                        <span *ngIf="rowData.details" class="detail">{{ rowData.details }} | &nbsp;</span>
                        <span *ngIf="rowData.size" class="detail">{{ rowData.size }} | </span>
                        <span class="detail"
                          >{{ 'demooij.list.column.net-weight' | translate }}: {{ rowData.net_weight }} | &nbsp;</span
                        >
                        <span class="detail">{{ rowData.packaging }}</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </div>

        <div #expandedRow [hidden]="true">
          <div *ngIf="editableTable && rowData?.id" class="expanded-row" [formGroup]="rowData.id | formGroup : form">
            <div class="row mb-3">
              <div class="input-number-wrap col d-flex">
                <cap-button
                  styleClass="default"
                  label="-"
                  (click)="updateFormValues(false, rowData.id, CustomColumn.quantity)"></cap-button>

                <cap-input-number
                  mode="decimal"
                  [formControlName]="CustomColumn.quantity"
                  [selectOnFocus]="true"
                  [placeholder]="'demooij.list.column.quantity-short' | translate"
                  [min]="0"
                  (blur)="onBlur(rowData.id, CustomColumn.quantity)">
                </cap-input-number>

                <cap-button
                  styleClass="default"
                  label="+"
                  (click)="updateFormValues(true, rowData.id, CustomColumn.quantity)"></cap-button>
              </div>

              <div
                *ngIf="isCashCarryCompany"
                class="input-number-wrap col"
                [ngClass]="{ 'd-none': rowData?.amount_per_package === 1 }">
                <cap-button
                  styleClass="default"
                  label="-"
                  [disabled]="form?.get(rowData.id)?.get(CustomColumn.unit)?.disabled"
                  (onClick)="updateFormValues(false, rowData.id, CustomColumn.unit)">
                </cap-button>

                <cap-input-number
                  mode="decimal"
                  [placeholder]="'demooij.list.column.unit' | translate"
                  [formControlName]="CustomColumn.unit"
                  [selectOnFocus]="true"
                  [min]="0"
                  (blur)="onBlur(rowData.id, CustomColumn.unit)">
                </cap-input-number>

                <cap-button
                  styleClass="default"
                  label="+"
                  [disabled]="form?.get(rowData.id)?.get(CustomColumn.unit)?.disabled"
                  (onClick)="updateFormValues(true, rowData.id, CustomColumn.unit)">
                </cap-button>
              </div>
            </div>

            <cap-input
              [formControlName]="CustomColumn.remarks"
              [selectOnFocus]="true"
              [placeholder]="'demooij.list.column.remarks' | translate"
              (blur)="onBlur(rowData.id, CustomColumn.remarks)">
            </cap-input>
          </div>
        </div>
      </tr>
    </ng-template>

    <ng-template pTemplate="groupheader" let-rowData>
      <tr pRowGroupHeader>
        <td [attr.colspan]="columns.length">
          <div class="main-group-name" [ngClass]="{ first: first }">
            <div class="separator"></div>
            <div class="name">{{ rowData.main_group_description }}</div>
            <div class="separator"></div>
          </div>
        </td>
      </tr>

      <tr class="table-header" [ngClass]="{ 'd-none': isMobile }">
        <td *ngFor="let column of columns" [class]="column.field || ''">
          {{ column.header | translate }}
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr *ngIf="columns" class="empty-message-row text-center">
        <td [attr.colspan]="columns.length">
          {{ 'table.no_results' | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>

<ng-template #loaderRef>
  <cap-info-table-skeleton></cap-info-table-skeleton>
</ng-template>

import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { ListRendererWidgetBase } from '@capturum/builders/list-renderer';
import { InputWidgetService } from '@core/services/input-widget.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { GeneralSelectors } from '@store/general/general.selectors';

@Component({
  selector: 'app-list-renderer-widget-status-icon',
  templateUrl: './list-renderer-widget-status-icon.component.html',
  styleUrls: ['./list-renderer-widget-status-icon.component.scss'],
})
export class ListRendererWidgetStatusIconComponent extends ListRendererWidgetBase implements OnInit, OnDestroy {
  public toCheckFieldValueKey: string;
  public toCheckFieldValueWithKey: string;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  private changesInTheRow$ = this.inputWidgetService.changesInTheRow.asObservable();

  constructor(
    private inputWidgetService: InputWidgetService,
    public readonly cdr: ChangeDetectorRef,
    protected readonly store: Store,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setInitialValue();
    this.updateRowOnChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public updateRowOnChanges(): void {
    this.changesInTheRow$
      .pipe(
        filter(({ id }) => {
          return this.item.id === id;
        }),
        filter(({ changedFields }) => {
          return !!changedFields && changedFields.hasOwnProperty(this.toCheckFieldValueKey);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(({ changedFields }) => {
        this.item[this.toCheckFieldValueKey] = changedFields[this.toCheckFieldValueKey];

        this.compareValues();

        this.cdr.detectChanges();
      });
  }

  protected setInitialValue(): void {
    const storeItem = this.store.selectSnapshot(GeneralSelectors.getSavedListRenderData)?.find((row) => {
      return row?.id === this.item?.id;
    });

    if (this.item && storeItem) {
      this.item[this.toCheckFieldValueKey] = storeItem[this.toCheckFieldValueKey];

      this.compareValues();
    }

    this.cdr.detectChanges();
  }

  protected compareValues(): void {
    const currentValue = parseInt(this.item[this.toCheckFieldValueKey]);
    const compareValue = parseInt(this.item[this.toCheckFieldValueWithKey]);

    switch (this.toCheckFieldValueKey) {
      case 'split_amount':
        this.item[this.field] = currentValue <= compareValue;
        break;

      default:
        this.item[this.field] = currentValue === compareValue;
        break;
    }
  }
}

import { Injectable } from '@angular/core';
import { ApiListResult, ApiService, ListOptions, ApiHttpService } from '@capturum/api';
import { Observable } from 'rxjs';
import { Product } from '@core/models/entity/product.model';
import { responseData } from '@capturum/builders/core';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService extends ApiService<Product> {
  protected endpoint = 'product';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public stockList(id: string, options?: ListOptions): Observable<ApiListResult> {
    let url = `/${this.endpoint}/${id}/stock/list`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get(url);
  }

  public copyProduct(productId: string): Observable<Product> {
    const url = `/${this.endpoint}/${productId}/copy`;

    return this.apiHttp.post(url, {}).pipe(responseData);
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PanelStateModel } from '@store/panel/panel-state.model';
import { UpdateItemInPanel, SetPanelConfig } from '@store/panel/panel.actions';

@State<PanelStateModel>({
  name: 'panel',
})
@Injectable()
export class PanelState {
  @Action(SetPanelConfig)
  public setPanelConfig(ctx: StateContext<PanelStateModel>, { config }: SetPanelConfig): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      config,
    });
  }

  @Action(UpdateItemInPanel)
  public UpdateItemInPanel(ctx: StateContext<PanelStateModel>, { item, panelTitle }): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      config: {
        ...state?.config,
        title: panelTitle,
        options: {
          ...state?.config?.options,
          item: item,
        },
      },
    });
  }
}

<div class="switch-companies">
  <ng-container *ngIf="companies$ | async as companies; else skeleton">
    <ng-container *ngIf="companies?.length">
      <ul class="companies">
        <p class="switch-label">
          {{ 'demooij.user-menu.switch-companies' | translate }}
        </p>

        <li
          *ngFor="let company of companies"
          [ngClass]="{ active: company?.id === (activeCompany$ | async)?.id }"
          (click)="changeCompany(company)">
          {{ company?.name }}
        </li>
      </ul>
    </ng-container>
  </ng-container>

  <ng-template #skeleton>
    <ul class="companies">
      <li *ngFor="let i of [1, 2, 3]">
        <cap-skeleton></cap-skeleton>
      </li>
    </ul>
  </ng-template>
</div>

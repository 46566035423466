import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    const exception = error?.error || error?.message || error?.originalError || '';
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(exception)) {
      window.location.reload();

      return;
    }

    if (error instanceof HttpErrorResponse) {
      // Handle the HTTP errors without logging the error to Sentry
      return;
    } else if (exception) {
      Sentry.captureException(exception);
    } else if (error) {
      /*
       If the error you're capturing is not an instance of Error, you can still capture it by passing it
       as the extra parameter of the Sentry.captureException() method and then providing more information
       about the error in the extra parameter.
      */
      Sentry.captureException(error, {
        extra: {
          headers: error.headers,
          ok: error.ok,
        },
      });
    }
  }
}

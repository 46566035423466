import { Customer } from './public-state.model';
import { Product } from '@core/models/entity/product.model';

export class SetProducts {
  public static readonly type = '[PUBLIC] Set products to place order';

  constructor(public products: Product[]) {}
}

export class SetEditedProducts {
  public static readonly type = '[PUBLIC] Set edited products to place order';

  constructor(public editedProducts: Product[]) {}
}

export class SetCustomer {
  public static readonly type = '[PUBLIC] Set customer details';

  constructor(public customer: Customer) {}
}

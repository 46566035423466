import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PriceInputComponent extends FieldType implements OnInit {
  public fractionDigits: number;
  public focused: boolean;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.fractionDigits = this.to.configurationOptions?.decimals;
  }

  public setFocus(focused: boolean): void {
    this.fractionDigits = focused ? 0 : this.to.configurationOptions?.decimals;
    this.focused = focused;

    if (this.formControl.value) {
      const valueInCents = this.formControl.value * 100;
      const valueInBancnotes = this.formControl.value / 100;

      // On very fast input unfocus, the formControl value is not set without the first Change detection
      this.cdr.detectChanges();
      this.formControl.setValue(focused ? valueInCents : valueInBancnotes);
      this.cdr.detectChanges();
    }
  }
}
